import {
  HideIcon,
  ShowIcon,
  LogoIcon,
  CopyIcon,
  EditIcon,
  CloseIcon,
  BackArrow,
  MessageIcon,
  InfoIcon,
  NotificationIcon,
  YouTubeIcon,
  InstagramIcon,
  FacebookIcon,
  TwitchIcon,
  TwitterIcon,
  TikTokIcon,
  LogoutIcon,
  BackIcon,
  PlusIcon,
  MessageCornerIcon,
  SearchIcon,
  ChatIcon,
  Community,
  LoadingSpinner,
  MentionNotification,
  PostNotification,
  MessageNotification,
  Challenge,
} from './icon-assets';
import { ImagePlaceholderIcon } from './icon-assets/image-placeholder';
import { TrophyIcon } from './icon-assets/trophy';
import { MedalIcon } from './icon-assets/medal';
import { DeleteIcon } from './icon-assets/delete';
import { BagIcon } from './icon-assets/bag';
import { TicketIcon } from './icon-assets/ticket';
import { CircleSquareIcon } from './icon-assets/circle-square';
import { QuestionIcon } from './icon-assets/question';
import { HomeIcon } from './icon-assets/home';
import { CalendarIcon } from './icon-assets/calendar';
import { CheckIcon } from './icon-assets/check';
import { RefreshIcon } from './icon-assets/refresh';
import { LogoLargeIcon } from './icon-assets/logo-large';
import { LinktreeIcon } from './icon-assets/linktree';
import { CustomIcon } from './icon-assets/custom';
import { LeaderboardIcon } from './icon-assets/leaderboard';
import { LinkIcon } from './icon-assets/link';
import { BeaconsIcon } from './icon-assets/beacons';
import { WorldIcon } from './icon-assets/world';
import { FanLogoBlack } from './icon-assets/fan-logo-black';
import { FanLogoWhite } from './icon-assets/fan-logo-white';
import { DownloadIcon } from './icon-assets/download';
import { SettingsIcon } from './icon-assets/settings';
import { RestrictionIcon } from './icon-assets/restriction';
import { LeaderboardsIcon } from './icon-assets/leaderboards';
import { GraphIcon } from './icon-assets/graph';
import { OpenMessage } from './icon-assets/open-message';
import { ClockIcon } from './icon-assets/clock';
import { SlimGraph } from './icon-assets/slim-graph';
import { PeopleIcon } from './icon-assets/people';
import { CheckBoxIcon } from './icon-assets/checkbox';
import { BoxIcon } from './icon-assets/box';
import { GemIcon } from './icon-assets/gem';
import { FrontArrowIcon } from './icon-assets/front-arrow';
import { LockIcon } from './icon-assets/lock';
import { NotificationBellIcon } from './icon-assets/notification-bell';
import { BoltSettingsIcon } from './icon-assets/bolt-settings';
import { MegaphoneIcon } from './icon-assets/megaphone';
import { Cube } from './icon-assets/cube';
import { OnboardIcon } from './icon-assets/onboard';
import { BadgeIcon } from './icon-assets/badge';
import { MessagesIcon } from './icon-assets/messages';
import { RankingIcon } from './icon-assets/ranking';

const componentsMap = {
  logo: LogoIcon,
  hide: HideIcon,
  show: ShowIcon,
  square: BoxIcon,
  beacons: BeaconsIcon,
  checkbox: CheckBoxIcon,
  copy: CopyIcon,
  edit: EditIcon,
  close: CloseIcon,
  backArrow: BackArrow,
  message: MessageIcon,
  download: DownloadIcon,
  info: InfoIcon,
  notification: NotificationIcon,
  youtube: YouTubeIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  twitch: TwitchIcon,
  twitter: TwitterIcon,
  tiktok: TikTokIcon,
  logout: LogoutIcon,
  back: BackIcon,
  messageCorner: MessageCornerIcon,
  imagePlaceholder: ImagePlaceholderIcon,
  delete: DeleteIcon,
  bag: BagIcon,
  ticket: TicketIcon,
  circleSquare: CircleSquareIcon,
  question: QuestionIcon,
  home: HomeIcon,
  calendar: CalendarIcon,
  check: CheckIcon,
  refresh: RefreshIcon,
  logoLarge: LogoLargeIcon,
  linktree: LinktreeIcon,
  leaderboard: LeaderboardIcon,
  link: LinkIcon,
  custom: CustomIcon,
  open: WorldIcon,
  fanLogoWhite: FanLogoWhite,
  fanLogoBlack: FanLogoBlack,
  plus: PlusIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  restriction: RestrictionIcon,
  leaderboards: LeaderboardsIcon,
  chat: ChatIcon,
  graph: GraphIcon,
  community: Community,
  loadingSpinner: LoadingSpinner,
  openMessage: OpenMessage,
  mentionNotification: MentionNotification,
  postNotification: PostNotification,
  messageNotification: MessageNotification,
  challenge: Challenge,
  trophy: TrophyIcon,
  medal: MedalIcon,
  clock: ClockIcon,
  slimGraph: SlimGraph,
  people: PeopleIcon,
  gem: GemIcon,
  frontArrow: FrontArrowIcon,
  lock: LockIcon,
  notificationPlus: NotificationBellIcon,
  boltSettings: BoltSettingsIcon,
  megaphone: MegaphoneIcon,
  Cube,
  OnboardIcon,
  BadgeIcon,
  MessagesIcon,
  RankingIcon,
};
export type UIIconType = keyof typeof componentsMap;
interface IIcon extends React.SVGProps<SVGSVGElement> {
  type: UIIconType;
}

export function Icon({ type: type, ...props }: IIcon) {
  if (!componentsMap[type]) {
    console.error(type + ' icon not found in ui');
    return null;
  }
  const Component = componentsMap[type];
  return <Component {...props} />;
}

import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { PATTERNS_CONSTANTS } from '@ui/constants';

export interface LoginForm {
  email: string;
  password: string;
  shopifyShopId?: number;
}

export function useLoginState(
  onLogin: (form: LoginForm, errorCallback: (e: string) => void) => void,
) {
  const { control, handleSubmit, setError } = useForm<LoginForm>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'all',
  });

  const [isLoading, setLoading] = useState(false);

  function handleLoginError(message: string) {
    setError('email', { message });
    setError('password', { message });
  }

  async function handleLogin(form: LoginForm) {
    setLoading(true);
    try {
      await onLogin(form, handleLoginError);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return {
    isLoading,
    email: {
      name: 'email' as 'email',
      control,
      rules: {
        required: 'Please enter your email',
        pattern: {
          value: PATTERNS_CONSTANTS.email,
          message: 'Please enter valid email address',
        },
      },
    },
    password: {
      name: 'password' as 'password',
      control,
      rules: {
        required: 'Please enter your password',
      },
    },
    onSubmit: handleSubmit(handleLogin),
  };
}

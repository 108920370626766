'use client';
import { useConversationContext, useMessageContext } from '@ui/components';
import { BaseMessageInput } from './base-message-input';

export const ConversationMessageInput = () => {
  const state = useConversationContext();
  const messageState = useMessageContext('creator');

  return (
    <BaseMessageInput
      onCreateMessage={messageState.onCreateMessage}
      onUpdateMessage={messageState.onUpdateMessage}
      selectedThread={state.selectedConversationSet?.conversation.main_thread}
      creatorId={state.creator_id}
      user_id={messageState.user_id}
      messageToEdit={messageState.messageToEdit}
      setMessageToEdit={messageState.setMessageToEdit}
      onSetOnboardingTriggerVisibility={messageState.onSetOnboardingTriggerVisibility}
      isCreating={messageState.isCreating}
      isPostingImage={messageState.isPostingImage}
      query={messageState.query}
    />
  );
};

import { Note, Title, MobileHeader, Avatar } from '@ui/components/atoms';
import { ILeaderboardProps, Leaderboard } from '../../leaderboard';
import { MobileHeaderContent } from '@ui/components/moleculas';

export interface IBadgeLeaderboardProps
  extends Omit<ILeaderboardProps, 'className' | 'emptyState'> {
  onBack(): void;
  avatar: string;
  fanId?: string;
}

export function BadgeLeaderboard({
  fans,
  status,
  fanId,
  hasMore,
  isLoading,
  currentFan,
  avatar,
  onNextPage,
  onBack,
  onFanOpen,
}: IBadgeLeaderboardProps) {
  return (
    <div className="absolute bottom-0 left-0 right-0 top-0 flex flex-1 flex-col bg-white lg:static ">
      <MobileHeader>
        <MobileHeaderContent
          onBack={onBack}
          title={'Badge Rank'}
          leftItem={<Avatar size="sm" src={avatar} />}
        />
      </MobileHeader>
      <div className="flex flex-1 flex-col px-7 lg:px-20 lg:py-8">
        <Title type={'mm'} className="mb-2 hidden lg:block">
          Badge Rank
        </Title>
        <Note type="s" className="block text-center lg:mb-8 lg:text-left">
          Earn badges faster to increase rarity, gain more points, and climb higher on the
          leaderboard. Rank is based on claim speed and total badge count.
        </Note>
        <Leaderboard
          emptyState={{
            title: 'Generating Leaderboard',
            message: 'Please check back later.',
            className: 'p-6',
          }}
          className="py-10"
          fans={fans}
          status={status}
          fanId={fanId}
          hasMore={hasMore}
          onNextPage={onNextPage}
          currentFan={currentFan}
          isLoading={isLoading}
          isBadgeLeaderboard={true}
          onFanOpen={onFanOpen}
          isCustom
        />
      </div>
    </div>
  );
}

'use client';
import { useEffect } from 'react';
import { useMessageContext } from '@ui/components/organisms/message';
import { ContentType, ContextUserType } from '@ui/constants/context';
import PostItem from './post-item';
import { CommentList, Separator } from '@ui/components';
import { ReplyRefProvider } from '@ui/contexts';
import { PostItemSkeleton } from './post-item-skeleton';
import { MessageUpdateFormPortal } from '../common/message-update-form-portal';
import { MessageUpdateFormProvider } from '@ui/contexts/message-update-form-context';
import { sendGAEvent } from '@next/third-parties/google';
import { PostItemMainRef } from './post-item-main-ref';

interface Props {
  userType: ContextUserType;
  contentType: ContentType;
  main_post_ref: string;
  useSEORoute?: boolean;
}

export const PostView = ({ userType, contentType, main_post_ref, useSEORoute }: Props) => {
  const messageState = useMessageContext(userType);

  useEffect(() => {
    if (userType !== 'creator' && messageState.singleMessage) {
      // Track the post view event for non-creators
      sendGAEvent('event', 'PostViewPage', {
        evet_label: 'Post View',
        postId: main_post_ref,
      });
    }
  }, [userType, messageState.singleMessage]);

  if (messageState.isLoadingSingleMessage) {
    return (
      <div className="mx-auto flex w-full max-w-[756px]">
        <PostItemSkeleton />
      </div>
    );
  }

  if (messageState.isSingleMessageError) {
    return (
      <div className="m-auto max-w-sm">
        <div className="flex flex-col justify-center space-y-4">
          <div className="text-center">
            <p className="font-nunito text-korDarkGrey text-3xl font-extrabold">
              {messageState?.singleMessageError?.status === 403
                ? 'Oops, post is restricted.'
                : messageState?.singleMessageError?.status === 422
                ? 'Content Removed'
                : 'Something went wrong'}
            </p>
            <p className="font-nunito text-korDarkGrey/40">
              {messageState?.singleMessageError?.status === 422
                ? 'This content is no longer available.'
                : messageState?.singleMessageError?.data?.error || 'Error loading post...'}
            </p>
          </div>
          <Separator className="" />
        </div>
      </div>
    );
  }

  if (!messageState.singleMessage) {
    return <div>No post found</div>;
  }

  return (
    <MessageUpdateFormProvider>
      <>
        <MessageUpdateFormPortal userType={userType} main_post_ref={main_post_ref} />

        <div className="mx-auto flex w-full max-w-[756px] flex-col space-y-4">
          {main_post_ref && messageState.mainPostRef && (
            <PostItemMainRef
              mainPostRef={messageState.mainPostRef}
              userType={userType}
              userId={messageState.user_id}
              communityPostRoute={messageState.communityPostRoute}
              communityCommentRoute={messageState.communityCommentRoute}
              communityReplyRoute={messageState.communityReplyRoute}
              query={messageState.query}
              contentType={contentType}
              fullDiscussionRoute={messageState.singleMessage.parent_post_slug}
              useSEORoute={useSEORoute}
            />
          )}
          <PostItem
            key={messageState.singleMessage.id}
            post={messageState.singleMessage}
            userType={userType}
            userId={messageState.user_id}
            creator_id={messageState.singleMessage.creator_id}
            communityPostRoute={messageState.communityPostRoute}
            isOwnPage
            query={messageState.query}
            contentType={contentType}
            useSEORoute={useSEORoute}
          />
          {contentType !== 'reply' && (
            <p className="font-nunito text-sm font-semibold">
              {contentType === 'post' ? 'Comments' : 'Replies'}
            </p>
          )}

          {contentType !== 'reply' && (
            <ReplyRefProvider>
              <CommentList
                contentType={contentType}
                userType={userType}
                post={messageState.singleMessage}
                creator_id={messageState.singleMessage.creator_id}
                user_id={messageState.user_id}
                main_post_ref={main_post_ref}
                enableReply={contentType === 'post'}
                useSEORoute={useSEORoute}
              />
            </ReplyRefProvider>
          )}
        </div>
      </>
    </MessageUpdateFormProvider>
  );
};

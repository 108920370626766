'use client';
import { useState } from 'react';

interface IToggle {
  value: boolean;
  setValue(value: boolean): void;
  label?: string;
}

export function EmailToggle({ value, setValue, label }: IToggle) {
  const toggleSwitch = () => {
    setValue(!value);
  };

  return (
    <div className="flex items-center gap-2">
      {label && <span className="text-sm font-medium">{label}</span>}
      <div
        className={`relative inline-block h-6 w-10 rounded-full transition-colors duration-300 ${
          value ? 'bg-orange-500' : 'bg-gray-300'
        }`}>
        <button
          className={`absolute left-0.5 top-0.5 h-5 w-5 transform rounded-full shadow transition-transform duration-300 ${
            value ? 'translate-x-4 bg-white' : 'translate-x-0 bg-white'
          }`}
          onClick={toggleSwitch}
          aria-checked={value}
          role="switch"
          type="button"
        />
      </div>
    </div>
  );
}

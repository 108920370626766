import { Dialog, DialogContent, DialogDescription, DialogTitle } from '@ui/components/ui/dialog';
import { Button, Text, DropImage, Banner } from '@ui/components';
import { ImagePlus, Ratio, X } from 'lucide-react';
import Cropper from 'react-easy-crop';
import { useEffect, useState } from 'react';
import { useLoader } from '@ui/contexts';
import { twMerge } from 'tailwind-merge';
import { toast } from 'sonner';

const minZoom = 0.4;

interface EasyCropProps {
  onCropComplete?: (croppedImage: string) => void;
  onDiscard?: () => void;
  width?: number;
  height?: number;
  image?: string;
  dropImageClassName?: string;
  imageClassName?: string;
  enableCrop?: boolean;
}

interface CropperModalProps {
  image: string;
  width: number;
  height: number;
  isOpen: boolean;
  onCropComplete: (croppedImage: string) => void;
  onClose: () => void;
}

interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface PreviewModalProps {
  image: string;
  isOpen: boolean;
  onClose: () => void;
}

const PreviewModal = ({ image, isOpen, onClose }: PreviewModalProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent className="w-full max-w-[1000px] bg-white" showCloseButton={false}>
        <div className="flex flex-col gap-6 p-4">
          <div className="flex justify-between items-center">
            <DialogTitle>Preview Banner</DialogTitle>
            <Button type="icon-secondary" onClick={onClose} size="small" className="rounded">
              <X className="w-4 h-4" />
            </Button>
          </div>

          <div className="flex w-full flex-col justify-around gap-8">
            <div className="flex flex-col gap-3">
              <Text type="l" className="font-semibold">
                Desktop
              </Text>
              <div className="bg-bgGrey flex h-40 w-full items-center justify-center rounded p-4">
                <Banner
                  image={image}
                  isLoading={false}
                  type="preview"
                  className="object-cover w-full"
                  showOverlay={false}
                />
              </div>
            </div>

            <div className="flex flex-col gap-3">
              <Text type="l" className="font-semibold">
                Mobile
              </Text>
              <div className="bg-bgGrey flex h-40 w-full items-center justify-center rounded p-4">
                <Banner
                  image={image}
                  isLoading={false}
                  type="preview"
                  className="max-w-[375px]"
                  showOverlay={false}
                />
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const EasyCrop = ({
  onCropComplete,
  width = 1280,
  height = 286,
  image,
  dropImageClassName,
  imageClassName,
  enableCrop = true,
}: EasyCropProps) => {
  const [displayImage, setDisplayImage] = useState<string | null>(null);
  const [cropImage, setCropImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  useEffect(() => {
    if (image) {
      setDisplayImage(image);
    }
  }, [image]);

  const handleFileSelected = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      if (result.startsWith('data:image')) {
        if (enableCrop) {
          setCropImage(result);
          setIsModalOpen(true);
        } else {
          setDisplayImage(result);
          if (onCropComplete) {
            onCropComplete(result);
          }
        }
      } else {
        console.error('Invalid file format detected.');
      }
    };
    reader.onerror = () => console.error('Error reading file.');
    reader.readAsDataURL(file);
  };

  const handleCropComplete = (croppedImage: string) => {
    if (!croppedImage) return;

    setDisplayImage(croppedImage);
    setCropImage(null);
    setIsModalOpen(false);
    if (onCropComplete) {
      onCropComplete(croppedImage);
    }
  };

  const handlePreview = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsPreviewOpen(true);
  };

  return (
    <>
      <div className="relative">
        <DropImage
          onFileSelected={handleFileSelected}
          className={twMerge('w-full rounded overflow-hidden h-full', dropImageClassName)}
          accept="image/jpeg,image/png,image/gif">
          {displayImage ? (
            <>
              <img
                src={displayImage}
                alt="Selected"
                className={twMerge('w-full h-full object-cover', imageClassName)}
                key={displayImage}
              />
              <div className="absolute top-2 right-2 z-10 flex gap-2">
                {enableCrop && (
                  <Button type="secondary" size="small" onClick={handlePreview}>
                    <Ratio className="w-4 h-4 mr-2" />
                    Preview
                  </Button>
                )}
                <Button type="secondary" size="small">
                  <ImagePlus className="w-4 h-4 mr-2" />
                  Change Image
                </Button>
              </div>
            </>
          ) : (
            <div className="flex flex-col h-full items-center justify-center gap-2">
              <Text type="s" className="text-center">
                Drop or click to select an image
              </Text>
              <Text type="l" className="text-gray-500">
                10MB max, jpg, jpeg, png, and gif
              </Text>
            </div>
          )}
        </DropImage>
      </div>

      {cropImage && (
        <CropperModal
          image={cropImage}
          width={width}
          height={height}
          onCropComplete={handleCropComplete}
          onClose={() => {
            setCropImage(null);
            setIsModalOpen(false);
          }}
          isOpen={isModalOpen}
        />
      )}

      {displayImage && (
        <PreviewModal
          image={displayImage}
          isOpen={isPreviewOpen}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </>
  );
};

const CropperModal = ({
  image,
  width,
  height,
  onCropComplete,
  onClose,
  isOpen,
}: CropperModalProps) => {
  const { openLoader, closeLoader } = useLoader();
  const cropSize = { width, height };
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(null);
  const [isGif, setIsGif] = useState(false);
  const [gifDimensions, setGifDimensions] = useState<{ width: number; height: number } | null>(
    null,
  );

  useEffect(() => {
    const checkImageType = async () => {
      if (image.startsWith('data:image/gif')) {
        setIsGif(true);
        // Get GIF dimensions
        const img = await createImage(image);
        setGifDimensions({ width: img.width, height: img.height });
        if (img.width !== width || img.height !== height) {
          const errorMessage = getDimensionErrorMessage();
          toast.error(
            `Unable to proceed: Your GIF dimensions don't match the required size of ${width}×${height} pixels. ${errorMessage}. Please ask your designer to provide a GIF with the exact dimensions or crop the GIF before uploading.`,
            {
              duration: 6000, // Increased duration to give users more time to read
            },
          );
        }
      }
    };
    checkImageType();
  }, [image]);

  const isGifDimensionsValid = gifDimensions
    ? gifDimensions.width === width && gifDimensions.height === height
    : false;

  const handleCropChange = (newCrop: { x: number; y: number }) => setCrop(newCrop);
  const handleZoomChange = (newZoom: number) => setZoom(newZoom);
  const handleCropComplete = (croppedArea: CropArea, pixelCrop: CropArea) =>
    setCroppedAreaPixels(pixelCrop);

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });

  const cropStaticImage = async (
    imageSrc: string,
    pixelCrop: CropArea,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ): Promise<string | null> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return null;

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return canvas.toDataURL('image/png');
  };

  const getCroppedImage = async (
    imageSrc: string,
    pixelCrop: CropArea,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ): Promise<string | null> => {
    try {
      // Check if it's a GIF and return early
      if (imageSrc.startsWith('data:image/gif')) {
        alert('GIF cropping is not supported. Please use a static image (JPG, PNG).');
        return null;
      }

      // Handle static images only
      const croppedImage = await cropStaticImage(imageSrc, pixelCrop, rotation, flip);
      if (!croppedImage) {
        console.error('Failed to crop image');
        return null;
      }

      return croppedImage;
    } catch (error) {
      console.error('Error processing image:', error);
      return null;
    }
  };

  const handleApply = async () => {
    if (isGif) {
      if (!isGifDimensionsValid) {
        toast.error(
          `Unable to proceed: Your GIF dimensions don't match the required size of ${width}×${height} pixels. ${getDimensionErrorMessage()}. Please ask your designer to provide a GIF with the exact dimensions or crop the GIF before uploading.`,
          {
            duration: 6000,
          },
        );
        return;
      }
      // For GIFs with correct dimensions, pass through without cropping
      onCropComplete(image);
      onClose();
      return;
    }

    // Existing logic for non-GIF images
    if (!croppedAreaPixels) {
      console.error('No crop area detected.');
      return;
    }

    try {
      openLoader('Cropping image...');
      const croppedImage = await getCroppedImage(image, croppedAreaPixels);
      if (croppedImage) {
        closeLoader();
        onCropComplete(croppedImage);
        onClose();
      } else {
        alert(
          'Unable to save image. The resulting file size exceeds 10MB. Please try a smaller image or reduce the dimensions.',
        );
        console.error('Failed to generate cropped image');
        closeLoader();
      }
    } catch (error) {
      console.error('Error cropping image:', error);
      closeLoader();
    }
  };

  const getDimensionErrorMessage = () => {
    if (!gifDimensions) return '';

    const widthDiff = gifDimensions.width - width;
    const heightDiff = gifDimensions.height - height;

    const widthMessage =
      widthDiff > 0
        ? `${gifDimensions.width - width}px too wide`
        : widthDiff < 0
        ? `${width - gifDimensions.width}px too narrow`
        : '';

    const heightMessage =
      heightDiff > 0
        ? `${gifDimensions.height - height}px too tall`
        : heightDiff < 0
        ? `${height - gifDimensions.height}px too short`
        : '';

    if (widthMessage && heightMessage) {
      return `Current GIF is ${widthMessage} and ${heightMessage}`;
    } else if (widthMessage) {
      return `Current GIF is ${widthMessage}`;
    } else if (heightMessage) {
      return `Current GIF is ${heightMessage}`;
    }
    return '';
  };

  return (
    <Dialog open={isOpen} onOpenChange={() => onClose()}>
      <DialogContent
        className="animate-none data w-screen h-screen max-w-none p-0 rounded-none sm:rounded-none bg-white/85"
        showCloseButton={false}>
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-between items-baseline gap-3 px-4 py-2 bg-white border-t z-50">
            <div>
              <DialogTitle className="text-black">
                {isGif ? 'GIF Dimension Check' : 'Crop Image'}
              </DialogTitle>
              <DialogDescription className="text-gray-500 space-y-1">
                {isGif ? (
                  <>
                    <strong>
                      Required GIF dimensions: {width}×{height} pixels
                    </strong>
                    {gifDimensions && (
                      <>
                        <br />
                        {isGifDimensionsValid ? (
                          <span className="text-green-600 font-medium">
                            ✅ Perfect! Your GIF matches the required dimensions.
                            <br />
                            Click &quot;Apply&quot; to proceed.
                          </span>
                        ) : (
                          <span className="text-red-500 font-medium">
                            ⚠️ {getDimensionErrorMessage()}
                            <br />
                            Current size: {gifDimensions.width}×{gifDimensions.height}
                            <br />
                            <span className="text-gray-700">
                              What to do:
                              <ul className="list-disc pl-5 mt-1">
                                <li>Ask your designer to provide a GIF with exact dimensions</li>
                                <li>Use a GIF editor to resize your image</li>
                                <li>Try a different GIF that matches the requirements</li>
                              </ul>
                            </span>
                          </span>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  `Adjust and crop the image to fit ${width}×${height}. Zoom / drag to position the image to fit the grid before applying.`
                )}
              </DialogDescription>
            </div>
            <Button type="icon-secondary" onClick={onClose} size="small" className="rounded">
              <X className="w-4 h-4" />
            </Button>
          </div>
          <div className="flex-1 flex justify-center items-center w-screen h-full">
            <div className="absolute top-0 left-0 right-0 w-screen h-full">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                minZoom={minZoom}
                aspect={width / height}
                cropSize={cropSize}
                onCropChange={handleCropChange}
                onZoomChange={handleZoomChange}
                onCropComplete={handleCropComplete}
                restrictPosition={isGif ? true : false}
              />
            </div>
          </div>
          <div className="flex justify-end gap-3 p-4 bg-white border-t z-50">
            <Button type="secondary" onClick={onClose} size="small">
              Dismiss
            </Button>
            <Button
              type="primary"
              onClick={handleApply}
              size="small"
              disabled={isGif && !isGifDimensionsValid}>
              Apply
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

import React from 'react';
import { twMerge } from 'tailwind-merge';

interface IMobileHeader {
  className?: string;
}
export function MobileHeader({ children, className }: React.PropsWithChildren<IMobileHeader>) {
  return (
    <div
      className={twMerge(
        'flex items-center justify-between gap-2 p-4 lg:hidden bg-white',
        className,
      )}>
      {children}
    </div>
  );
}

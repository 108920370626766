'use client';
import ReactModal from 'react-modal';

import { twMerge } from 'tailwind-merge';

const modalTypeMap = {
  center: {
    className:
      'outline-none absolute lg:left-1/2 lg:top-1/2 bottom-24 top-24 left-4 right-4 lg:right-auto lg:bottom-auto lg:w-auto flex lg:mx-0 lg:min-w-[500px] lg:-translate-x-1/2 lg:-translate-y-1/2',
    overlayClassName: 'z-50 bg-bgBlack bg-opacity-50 fixed top-0 left-0 right-0 bottom-0',
  },
  side: {
    className:
      'outline-none fixed bottom-0 right-0 top-0 w-full transition lg:w-[656px] lg:max-w-[50%]',
    overlayClassName: 'bg-bgBlack bg-opacity-50 fixed top-0 left-0 right-0 bottom-0 z-50',
  },
  full: {
    className: 'outline-none fixed bottom-0 right-0 top-0 w-full transition',
    overlayClassName: 'bg-transparent fixed top-0 left-0 right-0 bottom-0 z-50',
  },
};

interface IModalWrapper {
  id?: string;
  isOpen: boolean;
  type: keyof typeof modalTypeMap;
  className?: string;
  onClose?(e: React.MouseEvent): void;
}

export function ModalWrapper({
  isOpen,
  id,
  className,
  type,
  children,
  onClose,
}: React.PropsWithChildren<IModalWrapper>) {
  // TODO: consider replace modal
  return (
    <ReactModal
      ariaHideApp={false}
      id={id}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={true}
      onAfterOpen={() => (document.body.style.overflow = 'hidden')}
      onAfterClose={() => (document.body.style.overflow = 'unset')}
      className={twMerge(modalTypeMap[type].className, className)}
      overlayClassName={twMerge(modalTypeMap[type].overlayClassName)}
      onRequestClose={onClose} // Handle the closing event
      shouldCloseOnEsc={true}>
      {children}
    </ReactModal>
  );
}

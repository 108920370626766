import { Switch } from '@ui/components';
import { IConversationRestrictionOptions } from '../../conversation.typings';

interface IProps {
  onChangeRestrictions: (restrictions: IConversationRestrictionOptions) => void;
  restrictions: IConversationRestrictionOptions;
}

export const ConversationPermissions = ({ onChangeRestrictions, restrictions }: IProps) => {
  return (
    <div className="h-full w-full px-4 space-y-6">
      <div className="space-y-4">
        <p className="text-sm font-medium">Permissions</p>

        <div className="flex items-center justify-between">
          <label className="text-sm text-slate-500">Allow fans to react</label>
          <Switch
            checked={restrictions.fans_react}
            onCheckedChange={(checked) =>
              onChangeRestrictions({ ...restrictions, fans_react: checked })
            }
          />
        </div>

        <div className="flex items-center justify-between">
          <label className="text-sm text-slate-500">Allow fans to reply</label>
          <Switch
            checked={restrictions.fans_reply}
            onCheckedChange={(checked) =>
              onChangeRestrictions({ ...restrictions, fans_reply: checked })
            }
          />
        </div>

        <div className="flex items-center justify-between">
          <label className="text-sm text-slate-500">Allow fans to report</label>
          <Switch
            checked={restrictions.fans_report}
            onCheckedChange={(checked) =>
              onChangeRestrictions({ ...restrictions, fans_report: checked })
            }
          />
        </div>
      </div>
    </div>
  );
};

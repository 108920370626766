import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { PATTERNS_CONSTANTS } from '@ui/constants';

export interface ISignUpForm {
  name: string;
  email: string;
  displayName: string;
  phone: string;
  password: string;
  check?: boolean;
}

export type IAuthErrorCallback = (field: 'displayName' | 'email', e: string) => void;

type onSignUp = (form: ISignUpForm, errorCb: IAuthErrorCallback) => void;

export function useSignUpState(
  onSignUp: onSignUp,
  type: 'creator' | 'fan',
  defaultValues: any = {},
) {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ISignUpForm>({
    defaultValues: {
      name: '',
      displayName: '',
      phone: '',
      email: '',
      password: '',
      check: false,
      ...defaultValues,
    },
    mode: 'all',
  });

  const watchedFields = useWatch({
    control,
    name: [type === 'creator' ? 'displayName' : 'name', 'email', 'password', 'check'],
  });

  const [isLoading, setLoading] = useState(false);

  function handleSignUpError(field: 'email' | 'displayName', message: string) {
    setError(field, { message });
  }

  async function handleSignUp({ email, phone, password, name, displayName }: ISignUpForm) {
    try {
      setLoading(true);

      await onSignUp(
        {
          email,
          password,
          phone,
          name,
          displayName,
        },
        handleSignUpError,
      );
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  return {
    isLoading,
    register,
    checkBoxInput: {
      control,
      name: 'check' as 'check',
      rules: {
        required: "Please agree to KOR Crew's Terms of Service and Privacy Policy",
      },
    },
    nameInput: {
      control,
      name: 'name' as 'name',
      rules: {
        required: 'Please enter your full name',
        maxLength: {
          value: 30,
          message: 'name should be less than 30 symbols',
        },
      },
    },
    displayNameInput: {
      control,
      name: 'displayName' as 'displayName',
      rules:
        type === 'creator'
          ? {
              required: 'Please enter your display name name',
              pattern: {
                value: PATTERNS_CONSTANTS.username,
                message: 'Username can include only letters, numbers or underscores',
              },
              maxLength: {
                value: 30,
                message: 'Username should be less than 30 symbols',
              },
            }
          : {},
    },
    phoneInput: {
      control,
      name: 'phone' as 'phone',
      rules:
        type === 'fan'
          ? {
              minLength: {
                value: 6,
                message: 'Phone should be at least 6 characters',
              },
            }
          : {},
    },
    emailInput: {
      control,
      name: 'email' as 'email',
      rules: {
        required: 'Please enter your email',
        pattern: {
          value: PATTERNS_CONSTANTS.email,
          message: 'Please enter valid email address',
        },
      },
    },
    passwordInput: {
      name: 'password' as 'password',
      control,
      rules: {
        required: 'Please enter your password',
        minLength: {
          value: 5,
          message: 'Password should be at least 5 characters',
        },
      },
    },
    isFormValid: watchedFields.every((field) => field),
    isError: Object.keys(errors).length > 0,
    onSubmit: handleSubmit(handleSignUp),
  };
}

import { EmptyScreen } from '@ui/components/moleculas';
import { IConversationSet } from '@ui/components/organisms/conversation';
import { useMessageContext } from '@ui/components/organisms/message';
import React, { useId, useMemo, useCallback, useState, useEffect } from 'react';
import PostItem from './post-item';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ContextUserType } from '@ui/constants/context';
import { PostItemSkeleton } from './post-item-skeleton';
import { MessageUpdateFormPortal } from '../common/message-update-form-portal';
import { MessageUpdateFormProvider } from '@ui/contexts/message-update-form-context';
import { twMerge } from 'tailwind-merge';
import Link from 'next/link';
import { PinIcon } from 'lucide-react';

interface IProps {
  emptyState: {
    title: string;
    message: string;
  };
  userType: ContextUserType;
  isLoadingCommunityConversations: boolean;
  isCommunityConversationSuccess: boolean;
  communitySets: IConversationSet[];
  useSEORoute?: boolean;
}

const MemoizedPostItem = React.memo(PostItem);

export const PostList = ({
  emptyState,
  userType,
  isLoadingCommunityConversations,
  isCommunityConversationSuccess,
  communitySets,
  useSEORoute,
}: IProps) => {
  const id = useId();
  const [initialLoading, setInitialLoading] = useState(true);
  const messageState = useMessageContext(userType);

  const isLoading = useMemo(
    () => isLoadingCommunityConversations || messageState.isLoading,
    [isLoadingCommunityConversations, messageState.isLoading],
  );

  const showEmptyScreen = useMemo(() => {
    const hasCommunity = isCommunityConversationSuccess && communitySets.length > 0;
    const hasMessages = messageState.isSuccess && messageState.messages.length === 0;

    return hasCommunity && hasMessages && !isLoading;
  }, [
    isCommunityConversationSuccess,
    communitySets.length,
    messageState.isSuccess,
    messageState.messages.length,
    isLoading,
  ]);

  useEffect(() => {
    if (isCommunityConversationSuccess && messageState.isSuccess) {
      setInitialLoading(false);
    }
  }, [isCommunityConversationSuccess, messageState.isSuccess]);

  const nextPage = useCallback(() => {
    messageState.onNextPage();
  }, [messageState]);

  return (
    <MessageUpdateFormProvider>
      <>
        {messageState.messages.length > 0 && (
          <MessageUpdateFormPortal userType={userType} main_post_ref="" />
        )}
        <div>
          {!isLoading && !initialLoading && messageState.pinned_messages?.length > 0 && (
            <div className="mb-4">
              <div className="flex items-center justify-between mb-2 md:mb-2">
                <div className="flex items-center pl-3 md:pl-0">
                  <div className="flex items-center bg-orange-100 h-8 w-8 rounded-full justify-center top-[-10%] left-0 border border-primaryLight">
                    <PinIcon className="text-primaryActive font-thin h-4 w-4 fill-white" />
                  </div>
                  <p
                    className={twMerge(
                      'font-nunito text-sm font-semibold ml-2',
                      userType === 'fan' ? 'px-1 md:px-0' : '',
                    )}>
                    Pinned
                  </p>
                </div>
                {messageState.pinned_messages?.length > 0 && (
                  <Link
                    href={
                      userType === 'creator'
                        ? '/dashboard/community-guidelines'
                        : '/community-guidelines'
                    }
                    className=" text-primary pr-4 text-xs underline opacity-40 transition-colors duration-200 hover:text-orange-400 md:pr-0">
                    Community Guidelines
                  </Link>
                )}
              </div>
              <div className="space-y-2 w-full">
                {messageState.pinned_messages?.map((post) => (
                  <div key={post.id} className="min-w-[96%] flex-grow h-fit">
                    <MemoizedPostItem
                      key={post.id}
                      post={post}
                      userType={userType}
                      userId={messageState.user_id}
                      creator_id={post.creator_id}
                      communityPostRoute={messageState.communityPostRoute}
                      query={messageState.query}
                      contentType="post"
                      isPinned
                      useSEORoute={useSEORoute}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="flex justify-between mb-2">
            <p
              className={twMerge(
                'font-nunito text-sm font-semibold',
                userType === 'fan' ? 'px-4 md:px-0' : '',
              )}>
              Posts
            </p>
            {!messageState.pinned_messages?.length && (
              <Link
                href={
                  userType === 'creator'
                    ? '/dashboard/community-guidelines'
                    : '/community-guidelines'
                }
                className=" text-primary pr-4 text-xs underline opacity-40 transition-colors duration-200 hover:text-orange-400 md:pr-0">
                Community Guidelines
              </Link>
            )}
          </div>

          {(isLoading || initialLoading) && (
            <div className="p-3 md:p-0">
              <PostItemSkeleton />{' '}
            </div>
          )}

          <div className="pb-10">
            <InfiniteScroll
              className="space-y-2"
              dataLength={messageState.messages.length}
              next={nextPage}
              hasMore={messageState.hasMorePages}
              scrollableTarget={id}
              loader={
                <div className="my-2">
                  <PostItemSkeleton />
                </div>
              }>
              {messageState.messages.map((post) => (
                <MemoizedPostItem
                  key={post.id}
                  post={post}
                  userType={userType}
                  userId={messageState.user_id}
                  creator_id={post.creator_id}
                  communityPostRoute={messageState.communityPostRoute}
                  query={messageState.query}
                  contentType="post"
                  useSEORoute={useSEORoute}
                />
              ))}
            </InfiniteScroll>
          </div>

          {showEmptyScreen && !initialLoading && !messageState.pinned_messages?.length && (
            <EmptyScreen
              {...emptyState}
              className="mx-auto h-fit w-full max-w-sm bg-transparent lg:bg-transparent"
            />
          )}
        </div>
      </>
    </MessageUpdateFormProvider>
  );
};

const BG_OPACITY = '0.1';
export function getBadgeBackground(hex: string) {
  if (!hex) {
    return;
  }
  hex = hex.replace(/^#/, '');

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${BG_OPACITY})`;
}

// export function validateType(type: 1 | 2 | 3 | 4) {
//   const CORRECT_TYPES = [1, 2, 3, '1', '2', '3'];
//   if (!CORRECT_TYPES.includes(type)) {
//     console.error('Your badge have invalid type value', type);
//     return 1;
//   }

//   return type;
// }

export function validateType(type: number | string): 1 | 2 | 3 | 4 {
  const CORRECT_TYPES = [1, 2, 3, 4, '1', '2', '3', '4'];
  if (!CORRECT_TYPES.includes(type as any)) {
    console.error('Your badge have invalid type value', type);
    return 1;
  }

  // Convert string types to numbers
  if (typeof type === 'string') {
    return parseInt(type, 10) as 1 | 2 | 3 | 4;
  }

  return type as 1 | 2 | 3 | 4;
}

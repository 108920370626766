'use client';
import {
  ContextUserType,
  FilePreview,
  IMessagePayload,
  InputFileWithTrigger,
  ISelectedEmoji,
  Spinner,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  useToast,
} from '@ui/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Paperclip, Smile, Send, Link2, Pencil, X } from 'lucide-react';
import { Button } from '@ui/components';
import { useFiles } from '@ui/hooks';
import { MAX_FILES } from '@ui/hooks/file/constants';
import dynamic from 'next/dynamic';
import { twMerge } from 'tailwind-merge';
import ContentEditable, {
  ContentEditableRef,
} from '@ui/components/atoms/content-editable/content-editable';

const EmojiPicker = dynamic(
  () => import('@ui/components/moleculas/emoji-picker/emoji-picker').then((mod) => mod.EmojiPicker),
  { ssr: false },
);

export const initialState: IMessagePayload = {
  user_id: '',
  description: '',
  files: {
    images: [],
  },
  send_email: true,
};

interface Props {
  className?: string;
  innerClassName?: string;
  textAreaClassName?: string;
  fileRenderDivClassName?: string;
  useInputOnly?: boolean;
  selectedThread?: string;
  creatorId: string;
  user_id: string;
  messageToEdit?: any;
  isCreating?: boolean;
  isPostingImage?: boolean;
  query?: any;
  enableEmoji?: boolean;
  enableLinkButton?: boolean;
  enableFileUpload?: boolean;
  onChange?: (message: IMessagePayload) => void;
  onCreateMessage: (message: any) => Promise<any>;
  onUpdateMessage: (message: any, query?: any) => Promise<any>;
  setMessageToEdit: (message: any) => void;
  onSetOnboardingTriggerVisibility?: (visible: boolean) => void;
}

export const BaseMessageInput = ({
  className,
  innerClassName,
  useInputOnly,
  onChange,
  fileRenderDivClassName,
  onCreateMessage,
  onUpdateMessage,
  selectedThread,
  creatorId,
  user_id,
  messageToEdit,
  setMessageToEdit,
  onSetOnboardingTriggerVisibility,
  isCreating,
  isPostingImage,
  query,
  enableEmoji = true,
  enableLinkButton = true,
  enableFileUpload = true,
}: Props) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const toast = useToast();
  const { files, onSelectFiles, deleteFile, onDiscardFiles, addInitialFiles } = useFiles();
  const ref = useRef<ContentEditableRef>(null);
  const [message, setMessage] = useState<IMessagePayload>(initialState);

  useEffect(() => {
    setMessage((state) => ({ ...state, files: { images: files } }));
    if (onChange) onChange({ files: { images: files } });
  }, [files]);

  useEffect(() => {
    if (messageToEdit) {
      setMessage((state) => ({
        ...state,
        description: messageToEdit?.description || '',
        files: {
          images:
            messageToEdit?.files?.images.map((file: any, index: number) => ({
              src: file.source,
              file: null,
              id: index.toString(),
              preventUpload: true,
              meta: {
                mime_type: file.mime_type,
                name: file.name,
                size: file.size,
                source: file.source,
              },
            })) || [],
        },
      }));

      addInitialFiles(messageToEdit.files?.images || []);

      if (ref.current) {
        if (ref.current?.clear) {
          ref.current.clear();
        }
        ref.current.innerHTML = messageToEdit.description;
      }
    }
  }, [messageToEdit]);

  const onContentChange = (newContent: string) => {
    const content = newContent || ref.current?.innerHTML || message.description;
    setMessage((state) => ({ ...state, description: content }));
    if (onChange) onChange({ description: content });
  };

  const onEmojiSelect = (emoji: ISelectedEmoji) => {
    const div = ref.current;
    if (!div) return;

    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) {
      // If no selection, append to end
      const textNode = document.createTextNode(emoji.native);
      div.appendChild(textNode);
    } else {
      const range = selection.getRangeAt(0);
      const textNode = document.createTextNode(emoji.native);
      range.deleteContents();
      range.insertNode(textNode);

      // Move cursor after emoji
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    onContentChange(div.innerText);
  };

  const FilesRender = useMemo(() => {
    return files.map((file) => {
      return <FilePreview key={file.id} file={file} onDelete={() => deleteFile(file.id)} />;
    });
  }, [files]);

  const onSubmit = async () => {
    if (!useInputOnly) {
      if (messageToEdit) {
        await handleUpdateMessage();
        return;
      }

      try {
        if (isDisableSend) return;
        const res = await onCreateMessage?.({
          ...message,
          thread_id: selectedThread,
          creator_id: creatorId,
          user_id: user_id,
          single_message: true,
        });

        if (!res?.error) {
          setMessage(initialState);
          onDiscardFiles();
          if (ref.current?.clear) {
            ref.current.clear();
          }
        } else {
          toast({ title: 'Failed to send message' }, { type: 'error' });
        }
      } catch (e) {
        toast({ title: 'Failed to send message' }, { type: 'error' });
      }
    }
  };

  const handleUpdateMessage = async () => {
    if (!useInputOnly) {
      try {
        const content = message.description || ref.current?.innerHTML;
        const res = await onUpdateMessage?.(
          {
            id: messageToEdit?.id,
            description: content,
            files: message.files,
          },
          query,
        );

        if (!res?.error) {
          setMessage(initialState);
          onDiscardFiles();
          setMessageToEdit?.(null);
          if (ref.current?.clear) {
            ref.current.clear();
          }
          onSetOnboardingTriggerVisibility?.(true);
        } else {
          toast({ title: 'Failed to update message' }, { type: 'error' });
        }
      } catch (e) {
        toast({ title: 'Failed to update message' }, { type: 'error' });
      }
    }
  };

  const isDisableSend = useMemo(() => {
    return !message.description && files.length === 0;
  }, [message, files]);

  const isLoading = useMemo(() => {
    return isCreating || isPostingImage;
  }, [isCreating, isPostingImage]);

  const handleLinkButtonClick = () => {
    if (!ref.current?.openLinkDialog) return;
    ref.current.openLinkDialog();
  };

  const handleCancelEdit = () => {
    setMessageToEdit?.(null);
    setMessage(initialState);
    onDiscardFiles();
    if (ref.current?.clear) {
      ref.current.clear();
    }
    onSetOnboardingTriggerVisibility?.(true);
  };

  return (
    <TooltipProvider>
      <div className={twMerge('sticky bottom-0 w-full flex-auto overflow-hidden', className)}>
        {messageToEdit && (
          <div className="flex flex-row justify-between gap-2 text-xs items-center bg-korLightOrange px-4 py-2">
            <div className="flex flex-row gap-2 items-center">
              <Pencil className="h-4 w-4" />
              <p className="text-xs font-medium">Editing message</p>
            </div>
            <Button
              type="icon-secondary"
              size="smallIcon"
              onClick={handleCancelEdit}
              className="rounded-[5px] min-w-fit sm:min-w-fit lg:h-6 lg:w-6 p-2 m-0 h-6 w-6">
              <X className="h-4 w-4" />
            </Button>
          </div>
        )}
        {files.length > 0 && (
          <div
            className={twMerge(
              'border-input flex w-full flex-row gap-2 overflow-x-auto rounded-t-lg border bg-white p-4',
              fileRenderDivClassName,
            )}>
            {FilesRender}
          </div>
        )}

        <div className={twMerge('flex w-full flex-row space-x-2 bg-white p-4', innerClassName)}>
          <div className="bg-input flex w-full flex-row space-x-2 rounded p-2">
            <div className="flex flex-shrink-0 flex-row">
              {enableFileUpload && (
                <Tooltip>
                  <TooltipTrigger>
                    <InputFileWithTrigger
                      maxFiles={MAX_FILES}
                      className="flex h-[32px] items-center"
                      exceededFileLimit={files.length >= MAX_FILES}
                      onSelectFiles={onSelectFiles}>
                      <Button
                        type="icon-secondary"
                        className="h-6 w-6 rounded-full border-none bg-transparent">
                        <Paperclip className="h-4 w-4" />
                      </Button>
                    </InputFileWithTrigger>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Add File</p>
                  </TooltipContent>
                </Tooltip>
              )}

              {enableEmoji && (
                <Tooltip>
                  <TooltipTrigger>
                    <EmojiPicker
                      onEmojiSelect={onEmojiSelect}
                      className="flex h-[32px] items-center"
                      side="top"
                      open={openEmoji}
                      setOpen={setOpenEmoji}>
                      <Button
                        type="icon-primary"
                        className="h-6 w-6 rounded-full border-none bg-transparent"
                        onClick={() => setOpenEmoji(true)}>
                        <Smile className="h-4 w-4" />
                      </Button>
                    </EmojiPicker>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Emoji</p>
                  </TooltipContent>
                </Tooltip>
              )}

              {enableLinkButton && (
                <Tooltip>
                  <TooltipTrigger>
                    <Button
                      type="icon-secondary"
                      className="h-6 w-6 rounded-full border-none bg-transparent"
                      onClick={handleLinkButtonClick}>
                      <Link2 className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Insert Link</p>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>

            <ContentEditable
              ref2={ref}
              placeholder="Write a message..."
              containerClassName="w-full"
              contentEditableClassName="w-full focus-within:outline-none max-h-[25dvh] overflow-y-auto"
              placeholderClassName="text-sm text-gray-500"
              updatedContent={message.description}
              onChange={(content) => onContentChange(content)}
              autoFocus
            />
          </div>
          {!useInputOnly && (
            <div className="flex flex-shrink-0 flex-row space-x-2">
              <Button
                onClick={onSubmit}
                size="smallIcon"
                disabled={isDisableSend || isLoading}
                type="primary"
                className="min-w-[40px] rounded">
                {isLoading ? <Spinner className="animate-spin" /> : <Send className="h-4 w-4" />}
              </Button>
            </div>
          )}
        </div>
      </div>
    </TooltipProvider>
  );
};

'use client';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IConversation,
  IConversationRestrictionOptions,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useConversationContext,
} from '@ui/components';
import React, { useMemo, useState } from 'react';
import { Lightbulb, Hand } from 'lucide-react';
import { UpdateConversationDetails } from './update-conversation-details';
import { ConversationPermissions } from './conversation-permission';
import { usePostHogFeatureFlag } from '@ui/hooks';
interface IProps {
  children: React.ReactNode;
}
export const ConversationSettings = ({ children }: IProps) => {
  const isReplyFeatureFlagEnabled = usePostHogFeatureFlag('2-way-messages');
  const state = useConversationContext();
  const [conversation, setConversation] = useState<IConversation>(
    state.selectedConversationSet?.conversation || {
      description: '',
      name: '',
      fan_ids: [],
      creator_id: state.creator_id,
      id: '',
      public: true,
      main_thread: '',
    },
  );
  const [restrictions, setRestrictions] = useState<IConversationRestrictionOptions>(
    state.selectedConversationSet?.restrictions_list || {
      fans_react: false,
      fans_reply: false,
      fans_report: false,
    },
  );
  const [isOpen, setIsOpen] = useState(false);

  const isDisabled = useMemo(() => {
    return state.isUpdatingConversation;
  }, [state.isUpdatingConversation]);

  const onChangeDetails = (conversation: IConversation) => {
    setConversation((state) => ({ ...state, ...conversation }));
  };

  const onChangeRestrictions = (restrictions: IConversationRestrictionOptions) => {
    setRestrictions((state) => ({ ...state, ...restrictions }));
  };

  const handleSave = async () => {
    await state.onUpdate(conversation);
    await handleRestrictionsUpdate(conversation, restrictions);
    setConversation((state) => ({ ...state, ...conversation }));
    setRestrictions((state) => ({ ...state, ...restrictions }));
    setIsOpen(false);
  };

  const handleRestrictionsUpdate = async (
    conversation: IConversation,
    restrictions: IConversationRestrictionOptions,
  ) => await state.onUpdateConversationRestrictions(conversation, restrictions);

  const isBidirectional = useMemo(() => {
    return state.selectedConversationSet?.conversation?.conversation_type === 'bidirectional';
  }, [state.selectedConversationSet?.conversation?.conversation_type]);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="gap-0 bg-white p-0 sm:max-w-2xl sm:rounded"
        style={{ cursor: state.isUpdatingConversation ? 'progress' : 'default' }}>
        <DialogHeader className="border-b p-4">
          <DialogTitle className="text">Message Settings</DialogTitle>
        </DialogHeader>

        <div>
          <Tabs defaultValue="details" className="flex h-full min-h-[400px] flex-row">
            <TabsList className="bg-input flex h-full w-1/3 flex-col justify-start space-y-2 rounded-none p-2">
              <TabsTrigger
                value="details"
                className="w-full justify-start rounded-sm p-2 text-left">
                <Lightbulb className="mr-2 h-4 w-4" /> Details
              </TabsTrigger>
              {isReplyFeatureFlagEnabled && isBidirectional && (
                <TabsTrigger
                  value="permissions"
                  className="w-full justify-start rounded-sm p-2 text-left">
                  <Hand className="mr-2 h-4 w-4" />
                  Permissions
                </TabsTrigger>
              )}
            </TabsList>

            <div className="flex w-2/3 flex-col">
              <TabsContent value="details" className=" h-full">
                <UpdateConversationDetails
                  conversation={conversation}
                  onChangeDetails={onChangeDetails}
                />
              </TabsContent>

              {isReplyFeatureFlagEnabled && isBidirectional && (
                <TabsContent value="permissions" className=" h-full">
                  <ConversationPermissions
                    onChangeRestrictions={onChangeRestrictions}
                    restrictions={restrictions}
                  />
                </TabsContent>
              )}

              <DialogFooter className="justify-end p-2">
                <Button
                  type="primary"
                  size="small"
                  isLoading={state.isUpdatingConversation}
                  disabled={isDisabled}
                  onClick={handleSave}>
                  Save
                </Button>
              </DialogFooter>
            </div>
          </Tabs>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const LeaderboardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2248 20.6142C8.98575 20.6142 6.21875 20.1242 6.21875 18.1622C6.21875 16.2002 8.96775 14.4922 12.2248 14.4922C15.4638 14.4922 18.2308 16.1842 18.2308 18.1452C18.2308 20.1062 15.4818 20.6142 12.2248 20.6142Z"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.225 11.6912C14.351 11.6912 16.075 9.96819 16.075 7.84219C16.075 5.71619 14.351 3.99219 12.225 3.99219C10.099 3.99219 8.37502 5.71619 8.37502 7.84219C8.36902 9.96019 10.079 11.6842 12.198 11.6912H12.225Z"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6113 10.6352C19.8483 10.3042 20.7603 9.17619 20.7603 7.83319C20.7603 6.43219 19.7673 5.26219 18.4453 4.99219"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.1953 13.7891C20.9493 13.7891 22.4473 14.9781 22.4473 16.0401C22.4473 16.6651 21.9303 17.3461 21.1463 17.5301"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.8375 10.6352C4.5995 10.3042 3.6875 9.17619 3.6875 7.83319C3.6875 6.43219 4.6815 5.26219 6.0025 4.99219"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.252 13.7891C3.498 13.7891 2 14.9781 2 16.0401C2 16.6651 2.517 17.3461 3.302 17.5301"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

'use client';
import React, { useState } from 'react';
import { IEngagementScoreResponse } from './engagement-typings';
import { EngagementScoreModal } from './components/engagement-score-modal';
import ScoreWidget from './components/score-widget';
import { useScoreIncrease } from './hooks/use-score-increase';
import { AnimatedShadowWrapper } from './components/animated-shadow-wrapper';
import { cn } from '@ui/lib/utils';
import './components/engagement.css';

interface EngagementScoreProp {
  score: IEngagementScoreResponse;
  border?: boolean;
  size?: 'md' | 'sm' | 'lg' | undefined;
  isProfile?: boolean;
  isOtherProfile?: boolean;
  Title?: string;
  textClassName?: string;
  useIncreaseEffect?: boolean;
}

const EngagementScore: React.FC<EngagementScoreProp> = ({
  score,
  border,
  isProfile,
  size,
  Title,
  isOtherProfile,
  textClassName,
  useIncreaseEffect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { showIncrease, increaseAmount, animationCycle } = useScoreIncrease(score);

  const containerClass = cn(
    isProfile ? '' : '',
    'md:block',
    border && 'pl-4 pr-9 py-3 rounded-xl',
    border && !isOtherProfile && 'gradient-border',
    border && isOtherProfile && 'border-border border',
    textClassName && 'border-none',
  );

  return (
    <EngagementScoreModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      score={score}
      isProfile={isProfile}
      isOtherProfile={isOtherProfile}
      key={animationCycle}>
      <AnimatedShadowWrapper
        showAnimation={showIncrease}
        hasBorder={border}
        className={containerClass}>
        <div className={isOtherProfile && border ? 'bg-background rounded-xl' : ''}>
          <ScoreWidget
            score={score}
            size={size ? size : 'md'}
            Title={Title}
            showIncrease={showIncrease}
            increaseAmount={increaseAmount}
            border={border}
            textClassName={textClassName}
            useIncreaseEffect={useIncreaseEffect}
          />
        </div>
      </AnimatedShadowWrapper>
    </EngagementScoreModal>
  );
};

export default EngagementScore;

'use client';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Note, Text, LoadingSkeleton } from '@ui/components/atoms';
import { TrendingUp } from 'lucide-react';
import { IEngagementScoreResponse } from '../engagement-typings';
import { twMerge } from 'tailwind-merge';
import { Plus } from 'lucide-react';

interface ScoreWidgetProp {
  score: IEngagementScoreResponse;
  size?: 'sm' | 'md' | 'lg';
  Title?: string;
  border: boolean | undefined;
  showIncrease?: boolean;
  increaseAmount?: number;
  textClassName?: string;
  useIncreaseEffect?: boolean;
}

const ScoreWidget: React.FC<ScoreWidgetProp> = ({
  score = {},
  size = 'md',
  Title = 'Score',
  border,
  showIncrease,
  useIncreaseEffect = false,
  textClassName,
}) => {
  const sizeClasses = {
    sm: {
      container: 'h-10 w-10',
      icon: 'w-[20px] h-[20px]',
      text: 'text-[15px]',
      scoreLoad: 'w-12 h-5',
    },
    md: {
      container: 'h-[50px] w-[50px]',
      icon: 'w-[27px] h-[27px]',
      text: 'text-[20px]',
      scoreLoad: 'w-10 h-4',
    },
    lg: {
      container: 'h-[70px] w-[70px]',
      icon: 'w-[35px] h-[35px]',
      text: 'text-[40px]',
      scoreLoad: 'w-8 h-4',
    },
  };

  return (
    <div className={`flex flex-grow-0 gap-2 cursor-pointer items-center z-50 `}>
      <motion.div
        initial={{ scale: 1, backgroundColor: 'transparent' }}
        animate={{
          scale: showIncrease ? 1.1 : 1,
          backgroundColor: showIncrease ? 'rgba(0, 255, 0, 0.1)' : 'transparent',
          transition: {
            duration: 0.3,
            ease: [0.17, 0.67, 0.83, 0.67],
          },
        }}
        className={twMerge(
          '!bg-tabActive flex items-center justify-center rounded-full',
          sizeClasses[size].container,
          border && `border border-primary  ${showIncrease && 'border-white z-40'}`,
        )}>
        <TrendingUp className={`text-primary ${sizeClasses[size].icon}`} />
      </motion.div>
      <div className="flex flex-col relative">
        <AnimatePresence>
          {showIncrease && useIncreaseEffect && (
            <motion.div
              initial={{ scale: 0.5, opacity: 0.2, filter: 'blur(0px)' }}
              animate={{ scale: 0.9, opacity: 1, filter: 'blur(0.5px)' }}
              exit={{ scale: 1.3, opacity: 0, filter: 'blur(0px)' }}
              transition={{ duration: 1 }}
              className={`text-green-600 flex items-center space-x-1 font-bold absolute top-[-4px] right-[-20px]`}>
              {/* <span>{increaseAmount}</span> */}
              <Plus className="w-5 h-5" strokeWidth={4} />
            </motion.div>
          )}
        </AnimatePresence>
        <Text
          type="s"
          className={`leading-[0.4rem] font-semibold text-left ${textClassName} ${
            showIncrease && useIncreaseEffect && 'text-white z-40'
          } ${sizeClasses[size].text}`}>
          <span>
            {score?.overall_score?.toFixed(2) ?? (
              <LoadingSkeleton className={`${sizeClasses[size].scoreLoad} mb-1`} />
            )}
          </span>
        </Text>
        <Note
          type="xs"
          className={`${textClassName} ${showIncrease && useIncreaseEffect && 'text-white z-40'}`}>
          {Title}
        </Note>
      </div>
    </div>
  );
};

export default ScoreWidget;

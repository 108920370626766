import { twMerge } from 'tailwind-merge';

import { getBadgeBackground } from '@ui/components/organisms/badge/badge.utils';
import { IBadgeClaimType } from '../../badge-claim.typings';
import { badgeClaimClassName } from '../../badge-claim.style';
import { Avatar, Button, Icon } from '@ui/components/atoms';
import { IBadgeClaimUser } from '../../badge-claim.typings';
import korcrewLogo from '@ui/assets/images/Logo-text-dark.png';

interface IBadgeClaimInfoLayout {
  claimType: IBadgeClaimType;
  color: string;
  children: React.ReactNode;
  user: IBadgeClaimUser;
  onBack?(): void;
}

export function BadgeClaimInfoLayout({
  children,
  claimType,
  color,
  user,
  onBack,
}: IBadgeClaimInfoLayout) {
  const background = getBadgeBackground(color);

  const className = badgeClaimClassName[claimType];
  return (
    <div
      style={{ backgroundColor: background }}
      className={twMerge('lg:w-1/2', className.container.badge)}>
      <div
        className={twMerge(
          `flex h-full flex-col bg-white lg:basis-1/2 lg:bg-transparent lg:px-10`,
          className.container.badgeInternal,
        )}>
        <div
          className={twMerge(
            'hidden items-center justify-between py-10 lg:flex',
            className.badgeDetails.background,
          )}>
          <Button type="secondary" className="h-12 w-12 min-w-0 rounded-full p-0" onClick={onBack}>
            <Icon type="backArrow" />
          </Button>

          <img src={korcrewLogo.src} alt="Korcrew" className="w-[150px]" />
          <Avatar
            size="sm"
            src={user.avatar}
            className={user.showAvatar ? '' : 'invisible'}
            isSubscribed={user?.isSubscribed}
          />
        </div>
        {children}
      </div>
    </div>
  );
}

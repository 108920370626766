'use client';
import React, { useMemo, useEffect, useCallback, useRef, useState } from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
} from '@ui/components';
import { ContextUserType, IMessage, useMessageContext } from '@ui/components/organisms/message';
import { Button } from '@ui/components/ui/button';
import { Ellipsis, Flag, PencilOff, Trash, PinIcon } from 'lucide-react';
import { PostItemDeleteActionModal } from './post-item-delete-action-modal';
import { useReportContent } from '@ui/contexts';
import { useInteractionGuard } from '@interaction-guard/index';

interface Props {
  post: IMessage;
  userType: ContextUserType;
  userId: string;
  enableContentFlagging: boolean;
  reportLabel?: string;
  onUpdateTrigger: () => void;
  dropdownOpen: boolean;
  contentType?: string;
  isReply?: boolean;
  setDropdownOpen: (open: boolean) => void;
}

export const PostItemsMoreOptions: React.FC<Props> = ({
  post,
  userType,
  userId,
  enableContentFlagging,
  reportLabel,
  onUpdateTrigger,
  dropdownOpen,
  setDropdownOpen,
  contentType,
  isReply,
}) => {
  const dropDownContentRef = useRef<HTMLDivElement>(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const messageState = useMessageContext(userType);
  const { openReportContent, onReasonSelected } = useReportContent();
  const { interceptInteraction } = useInteractionGuard();

  const onDeleteOpen = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteModalOpen(true);
  }, []);

  useEffect(() => {
    const handleReasonSelected = (reason: string, message: IMessage) =>
      messageState.onReportMessage({ message_id: message.id, report: reason });
    onReasonSelected(handleReasonSelected);
  }, [onReasonSelected, messageState]);

  const showDropdownTrigger = useMemo(
    () => enableContentFlagging || userId === post.user_id,
    [enableContentFlagging, userId, post.user_id],
  );

  const onEdit = interceptInteraction((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onUpdateTrigger();
    setDropdownOpen(false);
  }, 'Join this KOR Space to use this feature. Claim this badge to join.');

  const canEdit = useMemo(
    () => post.user_id === userId && !post.block,
    [post.user_id, userId, post.block],
  );

  const onPin = interceptInteraction((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    messageState.onPinMessage(
      post.id,
      post.pinned_at ? false : true,
      userId,
      post.thread_id,
      post.conversation_id,
      contentType ? 'Post' : 'Comment',
    );
  }, 'Join this KOR Space to use this feature. Claim this badge to join.');

  const onDelete = interceptInteraction(
    onDeleteOpen,
    'Join this KOR Space to use this feature. Claim this badge to join.',
  );

  const onReport = interceptInteraction(
    () => openReportContent(post),
    'Join this KOR Space to use this feature. Claim this badge to join.',
  );

  return (
    <>
      <PostItemDeleteActionModal
        open={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        post={post}
        userType={userType}
        isReply={isReply}
      />

      {showDropdownTrigger && (
        <DropdownMenu open={dropdownOpen} onOpenChange={setDropdownOpen}>
          <DropdownMenuTrigger asChild>
            <Button size="icon" variant="ghost" className="rounded">
              <Ellipsis className="h-4 w-4 " />
            </Button>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-40" hideWhenDetached ref={dropDownContentRef}>
            <DropdownMenuGroup className="w-40">
              {canEdit && userType === 'creator' && (
                <DropdownMenuItem className="space-x-2" onClick={onPin}>
                  <PinIcon className="h-4 w-4" />
                  {post.pinned_at ? <span>Unpin</span> : <span>Pin</span>}
                </DropdownMenuItem>
              )}

              {canEdit && (
                <DropdownMenuItem className="space-x-2" onClick={onEdit}>
                  <PencilOff className="h-4 w-4" />
                  <span>Edit</span>
                </DropdownMenuItem>
              )}

              {userId === post.user_id && (
                <DropdownMenuItem className="space-x-2" onClick={onDelete}>
                  <Trash className="h-4 w-4" />
                  <span>Delete</span>
                </DropdownMenuItem>
              )}

              {enableContentFlagging && (
                <DropdownMenuItem className="space-x-2" onClick={onReport}>
                  <Flag className="h-4 w-4" />
                  <span>{reportLabel}</span>
                </DropdownMenuItem>
              )}
            </DropdownMenuGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};

export const RankingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.width || 18}
    height={props?.width || 18}
    viewBox="0 0 48 48"
    className="mr-0 lg:mr-1">
    <path
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      d="M43,25h-11v-7c0-1.654-1.346-3-3-3h-.551c-.245-1.692-1.691-3-3.449-3v-1.101c1.235-.252,2.294-.969,3.016-1.949,1.011-.115,1.944-.543,2.654-1.27.86-.84,1.33-1.97,1.33-3.18,0-1.38-1.12-2.5-2.5-2.5h-.5c0-1.103-.897-2-2-2h-6c-1.103,0-2,.897-2,2h-.5c-1.38,0-2.5,1.12-2.5,2.5,0,2.291,1.73,4.168,3.979,4.444.722.983,1.783,1.703,3.021,1.955v1.101c-1.759,0-3.204,1.308-3.449,3h-.551c-1.654,0-3,1.346-3,3v2H5c-1.65,0-3,1.35-3,3v22c0,1.65,1.35,3,3,3h38c1.65,0,3-1.35,3-3v-17c0-1.65-1.35-3-3-3ZM29.5,4c.28,0,.5.22.5.5,0,.67-.26,1.29-.74,1.77-.098.098-.203.185-.314.264.019-.178.054-.351.054-.534v-2h.5ZM18,4.5c0-.28.22-.5.5-.5h.5v2c0,.181.035.352.053.528-.634-.453-1.053-1.19-1.053-2.028ZM21,6V2h6v4c0,1.654-1.346,3-3,3s-3-1.346-3-3ZM23,14h2c.65,0,1.21.42,1.41,1h-4.82c.2-.58.76-1,1.41-1ZM4,45v-22c0-.55.45-1,1-1h11v24H5c-.55,0-1-.45-1-1ZM30,46h-12v-28c0-.551.448-1,1-1h10c.552,0,1,.449,1,1v28ZM44,45c0,.55-.45,1-1,1h-11v-19h11c.55,0,1,.45,1,1v17Z"></path>
  </svg>
);

export const MessagesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4901 12.435C11.4901 12.435 -0.486525 9.95744 3.67542 7.55489C7.18757 5.52759 19.2915 2.04204 20.9825 2.94264C21.8831 4.63364 18.3976 16.7376 16.3703 20.2497C13.9677 24.4117 11.4901 12.435 11.4901 12.435Z"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4922 12.4377L20.9846 2.94531"
      stroke={props?.stroke || '#2D3139'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

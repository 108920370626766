export const BadgeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4748 12.8906L16.9898 21.4166C17.0068 21.517 16.9927 21.6202 16.9494 21.7124C16.9062 21.8046 16.8358 21.8813 16.7477 21.9324C16.6597 21.9835 16.5581 22.0065 16.4566 21.9983C16.3551 21.9902 16.2585 21.9512 16.1798 21.8866L12.5998 19.1996C12.427 19.0705 12.217 19.0007 12.0013 19.0007C11.7856 19.0007 11.5756 19.0705 11.4028 19.1996L7.8168 21.8856C7.73812 21.9501 7.64166 21.989 7.54029 21.9972C7.43893 22.0054 7.33747 21.9825 7.24946 21.9315C7.16145 21.8805 7.09107 21.804 7.04772 21.712C7.00436 21.62 6.99009 21.5169 7.0068 21.4166L8.5208 12.8906"
      //   stroke="#616264"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke={props.stroke || '#616264'}
    />
    <path
      d="M12 14C15.3137 14 18 11.3137 18 8C18 4.68629 15.3137 2 12 2C8.68629 2 6 4.68629 6 8C6 11.3137 8.68629 14 12 14Z"
      stroke={props.stroke || '#616264'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

'use client';
import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { createPortal } from 'react-dom';
import ScoreWidget from './score-widget';
import EngagementMetrics from './engagement-metric-wrapper';
import { useScoreIncrease } from '../hooks/use-score-increase';
import { AnimatedShadowWrapper } from './animated-shadow-wrapper';
import { IEngagementScoreResponse } from '../engagement-typings';
import { EasyTab, EasyTabContent, EasyTabItem } from '@ui/components/moleculas';
import { Gem, BadgeCheckIcon } from 'lucide-react';
import ModalScore from './modal-score';
import badges from '../../../../assets/images/badges.png';
import { TrendingUp, Plus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { Text } from '@ui/components/atoms';
import { useAuth } from '@ui/hooks/auth/use-auth';

interface EngagementScoreFooterProp {
  score: IEngagementScoreResponse;
  className?: string;
}

const EngagementScoreFooter: React.FC<EngagementScoreFooterProp> = ({ score, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [portalRoot, setPortalRoot] = useState<HTMLDivElement | null>(null);
  const { isAuthenticated } = useAuth();

  const { showIncrease, increaseAmount, animationCycle } = useScoreIncrease(score);

  useEffect(() => {
    const portalElement = document.createElement('div');
    portalElement.id = 'engagement-footer-portal';
    document.body.appendChild(portalElement);
    setPortalRoot(portalElement);

    return () => {
      document.body.removeChild(portalElement);
    };
  }, []);

  const content = (
    <>
      <div
        className={twMerge('lg:hidden fixed block bottom-44 right-5', className)}
        onClick={() => isAuthenticated && setIsOpen(!isOpen)}
        style={{ zIndex: 99999 }}
        key={animationCycle}>
        <AnimatedShadowWrapper showAnimation={showIncrease} hasBorder>
          <motion.div
            className={`bg-white pointer-events-auto rounded-full ${
              showIncrease ? '' : 'shadow-lg '
            }flex items-center overflow-hidden`}
            initial={{ width: '50px' }}
            animate={{
              width: showIncrease ? '100px' : '50px',
            }}
            transition={{
              type: 'spring',
              damping: 25,
              stiffness: 300,
            }}>
            <motion.div
              initial={{ scale: 1, backgroundColor: 'transparent' }}
              animate={{
                scale: showIncrease ? 1.1 : 1,
                backgroundColor: showIncrease ? 'rgba(0, 255, 0, 0.1)' : 'transparent',
                x: showIncrease ? 10 : 0,
                opacity: showIncrease ? 0 : 1,
              }}
              transition={{ duration: 0.3, ease: [0.17, 0.67, 0.83, 0.67] }}
              className={twMerge(
                'bg-tabActive flex flex-col items-center justify-center rounded-full h-12 w-12 border border-primary min-w-[50px] min-h-[50px]',
                showIncrease && 'border-white z-40',
              )}>
              <TrendingUp className={`text-primary w-5 h-4`} />
              <Text type="xs" className={`${showIncrease ? 'text-white' : 'text-note'} !mt-0`}>
                Score
              </Text>
            </motion.div>
            <AnimatePresence>
              {showIncrease && (
                <motion.div
                  initial={{ opacity: 0, x: -10 }}
                  animate={{ opacity: 1, x: -24 }}
                  exit={{ opacity: 0, x: -10 }}
                  transition={{ duration: 0.2 }}
                  className="flex items-center ml-2 relative z-40">
                  <div className="flex flex-col items-center justify-center">
                    <div className="font-semibold text-white text-sm leading-tight">
                      {score?.overall_score?.toFixed(2)}
                    </div>
                    <div className="text-xs text-white">Score</div>
                  </div>
                  <motion.div
                    initial={{ scale: 0.5, opacity: 0.2 }}
                    animate={{ scale: 0.9, opacity: 1 }}
                    exit={{ scale: 1.3, opacity: 0 }}
                    transition={{ duration: 1 }}
                    className="text-green-600 absolute -top-1 -right-4 flex items-center">
                    <Plus className="w-4 h-4" strokeWidth={4} />
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        </AnimatedShadowWrapper>
      </div>
      <div className="fixed -bottom-4 left-0 right-0 z-10 pointer-events-none block lg:hidden pt-1">
        <div className="bg-white pointer-events-auto rounded-t-lg shadow-2xl shadow-black">
          <div
            onClick={() => setIsOpen(!isOpen)}
            className="w-full text-black py-3 px-4 rounded-t-lg relative">
            <ScoreWidget
              score={score}
              border
              size="md"
              increaseAmount={increaseAmount}
              showIncrease={showIncrease}
              Title="Overall score"
            />
            <div className="absolute right-2 top-4">{isOpen ? <ChevronDown /> : <ChevronUp />}</div>
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{
                  type: 'spring',
                  damping: 30,
                  stiffness: 300,
                }}
                className="bg-white rounded-t-lg shadow-lg overflow-hidden">
                <EasyTab defaultTab="Engagement" className="p-2">
                  <div className="flex justify-between space-x-2 w-full mb-4">
                    <EasyTabItem
                      label="Engagement"
                      className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg "
                      useBottomActiveBorder={false}
                      useFullBorderWhenActive
                      icon={Gem}
                    />
                    <EasyTabItem
                      label="Badges"
                      className="flex justify-center p-3 w-[48%] border border-primaryLight rounded-lg"
                      useBottomActiveBorder={false}
                      useFullBorderWhenActive
                      icon={BadgeCheckIcon}
                    />
                  </div>
                  <EasyTabContent label="Engagement" className="min-h-fit">
                    <div className="w-full mb-4">
                      <ModalScore
                        score={score?.total_engagement_score}
                        label="Your engagement score"
                        allScoreResponse={score}
                      />
                    </div>
                    <div className="overflow-y-scroll h-[350px] pb-[100px] scrollbar-none">
                      <EngagementMetrics score={score} />
                    </div>
                  </EasyTabContent>
                  <EasyTabContent label="Badges" className="min-h-fit">
                    <div className="w-full mb-4">
                      <ModalScore
                        score={score?.badges_score}
                        label="Your badge score"
                        link="View all badges"
                        allScoreResponse={score}
                        setIsOpen={setIsOpen}
                      />
                    </div>
                    <div className="max-h-[350px] min-h-[350px] w-full rounded-sm flex justify-center items-center">
                      <img
                        src={badges.src}
                        alt="badges"
                        className="h-[280px] md:h-[330px] object-cover rounded-sm w-[100%]"
                      />
                    </div>
                  </EasyTabContent>
                </EasyTab>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );

  return portalRoot ? createPortal(content, portalRoot) : null;
};

export default EngagementScoreFooter;

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from '@radix-ui/react-dropdown-menu';
import { Button } from '@ui/components/atoms/button';
import { IMessage } from '@ui/components/organisms';
import { ChevronDown, Pencil } from 'lucide-react';

interface IProps {
  onEdit: () => void;
}
export function MessageItemDropdown({ onEdit }: IProps) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Button type="icon-primary" className="ml-auto mt-1 mr-1 outline-none focus:outline-none">
          <ChevronDown className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-32 bg-white p-2 rounded m-2 z-50 outline shadow">
        <DropdownMenuGroup>
          <DropdownMenuItem
            className="text-sm flex flex-row items-center focus:outline-none cursor-pointer"
            onClick={onEdit}>
            <Pencil className="h-4 w-4 mr-2" />
            Edit
          </DropdownMenuItem>
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

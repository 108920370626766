import React, { useRef } from 'react';
import google from '@ui/assets/images/google.png';
import { Button, IButtonSize } from '../atoms/button';
import { sendGAEvent } from '@next/third-parties/google';

interface IOauthButton {
  type: string;
  url: string;
  size?: IButtonSize;
  claim?: string;
}

export const OauthButton: React.FC<IOauthButton> = ({ type, url, size, claim }) => {
  const image = {
    google: google,
  }[type];

  const oauthEventSentRef = useRef(false);

  const handleOAuthClick = () => {
    if (!oauthEventSentRef.current) {
      sendGAEvent('event', 'SignInWithGoogleClicked', {
        signin_time: new Date().toISOString(),
      });
      oauthEventSentRef.current = true;
    }

    const finalURL = `${process.env['NEXT_PUBLIC_API_URL']}${url}${claim ? `?claim=${claim}` : ''}`;
    window.open(finalURL, '_self');
    // window.open(`${process.env['NEXT_PUBLIC_API_URL']}${url}`, '_self');
  };

  return (
    <Button onClick={handleOAuthClick} type="secondary" className="mt-4 w-full" size={size}>
      <img src={image.src} alt="icon" className="mr-[10px] h-[24px] w-[24px]" />
      <span>{claim ? 'Login' : 'Continue'} With Google</span>
    </Button>
  );
};

'use client';
import { ReactNode, useState, useMemo, useEffect } from 'react';
import {
  Button as KorButton,
  CohortSelectionModal,
  CustomLeaderboardSelectionModal,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  EasyTab,
  EasyTabContent,
  EasyTabItem,
  Icon,
  ICreateConversation,
  Input,
  TConversationParticipantSource,
  useConversationContext,
} from '@ui/components';
import { Terminal, X } from 'lucide-react';
import { ConversationParticipantSelectionCount } from './conversation-participant-selection-count';
import { Button } from '@ui/components/ui/button';
import { EmailsSelectionModal } from './email-selection-modal';
import { useSearchParams, useRouter } from 'next/navigation';
import { usePostHogFeatureFlag } from '@ui/hooks';

const initial: ICreateConversation = {
  description: '',
  name: '',
  creator_id: '',
  restrictions: {
    fans_reply: false,
  },
  public: false,
};
interface IProps {
  children: ReactNode;
}
export const CreateConversationModal: React.FC<IProps> = ({ children }: IProps) => {
  const isReplyFeatureFlagEnabled = usePostHogFeatureFlag('2-way-messages');
  const state = useConversationContext();
  const [isOpen, setIsOpen] = useState(false);
  const [defaultTab, setDefaultTab] = useState('From Leaderboards');
  const searchParams = useSearchParams();
  const router = useRouter();
  const [conversation, setConversation] = useState<ICreateConversation>(initial);
  const [participantSource, setParticipantSource] = useState<TConversationParticipantSource | null>(
    null,
  );

  const shouldOpenModal = useMemo(() => {
    return searchParams.get('openCreateModal') === 'true';
  }, [searchParams]);

  const isDisabled = useMemo(() => {
    const hasLeaderboard =
      participantSource &&
      participantSource.type === 'leaderboard' &&
      participantSource.custom_leaderboard_id;
    const hasCohort =
      participantSource && participantSource.type === 'cohort' && participantSource.cohort_id;
    const hasEmails =
      participantSource &&
      participantSource.type === 'email' &&
      participantSource.emails.length > 0;
    const hasBasics = conversation.name?.length > 1;
    const hasSource = hasLeaderboard || hasCohort || hasEmails;
    return !hasBasics || state.isCreatingConversation || !hasSource;
  }, [state.isCreatingConversation, conversation.name, participantSource, shouldOpenModal]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setConversation({ ...conversation, name: e.target.value });
  const onAddMembers = (participantSource: TConversationParticipantSource) =>
    setParticipantSource(participantSource);

  useEffect(() => {
    if (shouldOpenModal) {
      setDefaultTab('From Cohorts');
      const storedData = sessionStorage.getItem('pendingConversationData');
      if (storedData) {
        const parsedData = JSON.parse(storedData);

        // Set the conversation data
        setConversation((currentConvo) => ({
          ...currentConvo,
          ...parsedData,
          name: '',
        }));

        // Set participant source if cohort_id exists
        if (parsedData.cohort_id) {
          const source: TConversationParticipantSource = {
            type: 'cohort' as const,
            cohort_id: parsedData.cohort_id,
            label: parsedData.name,
            count: parsedData.count,
          };
          setParticipantSource(source);
        }

        // Clean up session storage
        sessionStorage.removeItem('pendingConversationData');
      }
      setIsOpen(true);
    }
  }, [searchParams]);

  const handleCreate = async () => {
    try {
      const response = await state.onCreateConversation({
        ...conversation,
        ...participantSource,
        creator_id: state.creator_id,
        ...(isReplyFeatureFlagEnabled
          ? {
              conversation_type: 'bidirectional',
            }
          : {}),
      });

      if ('data' in response) {
        setIsOpen(false);
        setParticipantSource(null);
        setConversation(initial);
        state.onResetPage();
        state.setSelectedConversationSet(null);
        shouldOpenModal && router.replace('/dashboard/messages');
      }
    } catch (error) {}
  };

  const onClose = () => {
    router.replace('/dashboard/messages');
    setIsOpen(false);
    setParticipantSource(null);
    setConversation(initial);
    state.onResetPage();
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        } else {
          setIsOpen(true);
        }
      }}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        showCloseButton={false}
        className="bg-white p-6 sm:max-w-lg"
        style={{ cursor: state.isCreatingConversation ? 'progress' : 'default' }}>
        <X className="h-4 w-4 absolute right-4 top-4 cursor-pointer" onClick={onClose} />
        <DialogHeader>
          <DialogTitle className="text-2xl">Create a New Message</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4 space-y-4">
          <div className="space-y-2">
            <p className="text-muted-foreground font-medium">Add Participants</p>
            <div className="flex flex-col justify-between space-y-4">
              <EasyTab defaultTab={defaultTab} className="min-h-[80px] overflow-hidden space-y-4">
                <div className="flex">
                  <EasyTabItem
                    label="From Leaderboards"
                    className="p-2 border-t border-b border-l rounded-l-sm"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                    disabled={shouldOpenModal}
                  />
                  <EasyTabItem
                    label="From Cohorts"
                    className="p-2  border-t border-b border-l"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                  />
                  <EasyTabItem
                    label="From Email"
                    className="p-2 border rounded-r-sm"
                    useBottomActiveBorder={false}
                    useFullBorderWhenActive
                    disabled={shouldOpenModal}
                  />
                </div>

                <EasyTabContent label="From Leaderboards" className="min-h-fit">
                  <CustomLeaderboardSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'leaderboard' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Select Leaderboard
                      </Button>
                    )}
                  </CustomLeaderboardSelectionModal>
                </EasyTabContent>

                <EasyTabContent label="From Cohorts" className="min-h-fit">
                  <CohortSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'cohort' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Select Cohort
                      </Button>
                    )}
                  </CohortSelectionModal>
                </EasyTabContent>

                <EasyTabContent label="From Email" className="min-h-fit">
                  <EmailsSelectionModal onAddMembers={(e) => onAddMembers(e)}>
                    {participantSource?.type === 'email' ? (
                      <ConversationParticipantSelectionCount selection={participantSource} />
                    ) : (
                      <Button variant="outline" size="sm" className="rounded-sm h-9">
                        <Icon type="plus" className="mr-2 rounded-full" /> Add Emails
                      </Button>
                    )}
                  </EmailsSelectionModal>
                </EasyTabContent>
              </EasyTab>
            </div>
          </div>
          <div>
            <Input
              type="text"
              label="Message title"
              placeholder="Enter the title of the message"
              onChange={handleNameChange}
              value={conversation.name}
            />
            <div className="mt-4 flex flex-row gap-2 text-xs">
              <Terminal className="h-6 w-8" />
              <div className="flex flex-col">
                <span className="text-sm font-medium">
                  It is recommended to choose a unique message title
                </span>
                <span className="">This title will be displayed to the fan</span>
              </div>
            </div>
          </div>

          {/* <div>
            <ConversationMessageInput
              className="relative w-full"
              innerClassName="p-0"
              useInputOnly
              onChange={onMessageChange}
              textAreaClassName="min-h-[100px]"
              fileRenderDivClassName="border-none"
            />
          </div> */}
        </div>

        <DialogFooter className="justify-end">
          <KorButton
            type="primary"
            size="small"
            isLoading={state.isCreatingConversation}
            disabled={isDisabled}
            onClick={handleCreate}>
            Create
          </KorButton>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

'use client';
import { Button } from '@ui/components/atoms';
import {
  ContextUserType,
  IMessage,
  IMessagesQueryParams,
  useMessageContext,
} from '@ui/components/organisms/message';
import { abbreviateNumber, cn } from '@ui/lib/utils';
import { ArrowBigDown, ArrowBigUp, MessageSquareDot } from 'lucide-react';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { sendGAEvent } from '@next/third-parties/google';
import { PostCommentReactions } from '../common/post-comment-reactions';
import { useScoreAnimation } from '@ui/hooks';
import AnimatedScoreIncrease from '@ui/components/organisms/engagement-score/components/animated-score-increase';
import { useInteractionGuard } from '@interaction-guard/index';

/**
 *  applies to both upvote and downvote
 *  0 - to indicate undo vote
 *  1 - to indicate vote
 */

interface Props {
  post: IMessage;
  userType: ContextUserType;
  hierarchyType: 'post' | 'comment' | 'reply';
  enableReply: boolean;
  query?: IMessagesQueryParams;
  onTriggerReplyForm: () => void;
}
export const PostItemReactions = ({
  post,
  userType,
  hierarchyType,
  onTriggerReplyForm,
  enableReply,
  query,
}: Props) => {
  const messageState = useMessageContext(userType);
  const { interceptInteraction } = useInteractionGuard();
  const { scoreAnimation, triggerScoreAnimation } = useScoreAnimation();

  const hasUpvoted = useMemo(() => {
    if (!post.user_votes) return false;
    return post.user_votes[messageState.user_id] === 'upvote';
  }, [post.user_votes, messageState.user_id]);

  const hasDownVoted = useMemo(() => {
    if (!post.user_votes) return false;
    return post.user_votes![messageState.user_id] === 'downvote';
  }, [post.user_votes, messageState.user_id]);

  const hasVoted = useMemo(() => {
    return hasUpvoted || hasDownVoted;
  }, [hasUpvoted, hasDownVoted]);

  const onVote = interceptInteraction((event: any, action: 'upvote' | 'downvote') => {
    event.preventDefault();
    event.stopPropagation();

    let voteValue = 1;
    let eventAction = '';
    let shouldTriggerAnimation = false;

    if (action === 'upvote') {
      if (hasUpvoted) {
        voteValue = -1;
        eventAction = 'Remove Upvote';
      } else if (hasDownVoted) {
        voteValue = 1;
        eventAction = 'Change to Upvote';
      } else {
        eventAction = 'Add Upvote';
        shouldTriggerAnimation = true;
      }
    } else if (action === 'downvote') {
      if (hasDownVoted) {
        voteValue = -1;
        eventAction = 'Remove Downvote';
      } else if (hasUpvoted) {
        voteValue = 1;
        eventAction = 'Change to Downvote';
      } else {
        eventAction = 'Add Downvote';
        shouldTriggerAnimation = true;
      }
    }

    messageState.onReactToMessage(
      post.id,
      post.slug,
      action,
      voteValue,
      query || messageState.query,
    );

    if (shouldTriggerAnimation) {
      triggerScoreAnimation(0.5, action);
    }

    sendGAEvent('event', 'UserReaction', {
      event: 'vote_action',
      event_action: eventAction,
      event_label: action,
      post_id: post.id,
      user_type: userType,
    });
  }, 'Join this KOR Space to use this feature. Claim this badge to join.');

  const handleTriggerReplyForm = () => {
    if (userType !== 'creator' && hierarchyType === 'post') {
      sendGAEvent('event', 'CommentIconClicked', {
        creatorId: post.creator_id,
        event_label: 'Comment Icon Click',
        postId: post.id,
      });
    }
    onTriggerReplyForm();
  };

  const onReact = interceptInteraction((event: any) => {
    event.preventDefault();
    event.stopPropagation();
  }, 'Join this KOR Space to use this feature. Claim this badge to join.');

  return (
    <div className="relative flex flex-col items-start justify-between md:flex-row md:items-center">
      <AnimatedScoreIncrease
        className={cn(
          'absolute text-green-500',
          scoreAnimation.position === 'upvote' && 'top-[-25px] left-0',
          scoreAnimation.position === 'downvote' && 'top-[-25px] left-[70px]',
        )}
        scoreAnimation={scoreAnimation}
        visiblePositions={['upvote', 'downvote']}
        userType={userType}
      />
      <div className="flex space-x-2">
        <div
          className={twMerge(
            'bg-input flex items-center rounded-full',
            hasVoted ? 'bg-primary text-white' : '',
          )}>
          <Button
            onClick={(e) => onVote(e, 'upvote')}
            type="secondary"
            size="smallIcon"
            className="h-fit w-fit min-w-fit rounded-full bg-transparent p-1 hover:bg-black/20 lg:h-fit lg:w-fit lg:min-w-fit">
            <ArrowBigUp
              className={twMerge(
                'text-korDarkGrey h-5 w-5',
                hasUpvoted ? 'fill-white stroke-white' : '',
                hasVoted ? 'stroke-white ' : '',
              )}
            />
          </Button>
          {
            <span
              className="text-korDarkGrey text-xs text-inherit"
              style={{ fontSize: 'x-small', color: hasVoted ? 'white' : '' }}>
              {abbreviateNumber(post.reaction?.upvotes)}
            </span>
          }
          <Button
            onClick={(e) => onVote(e, 'downvote')}
            type="secondary"
            size="smallIcon"
            className="h-fit w-fit min-w-fit rounded-full bg-transparent p-1 hover:bg-black/20 lg:h-fit lg:w-fit lg:min-w-fit">
            <ArrowBigDown
              className={twMerge(
                'text-korDarkGrey h-5 w-5',
                hasDownVoted ? 'fill-white stroke-white' : '',
                hasVoted ? 'stroke-white' : '',
              )}
            />
          </Button>
          {post.reaction?.downvotes > 0 && (
            <span
              className="text-korDarkGrey mr-2 text-xs text-inherit"
              style={{ fontSize: 'x-small', color: hasVoted ? 'white' : '' }}>
              -{abbreviateNumber(post.reaction?.downvotes)}
            </span>
          )}
        </div>
        {enableReply && (
          <Button
            onClick={handleTriggerReplyForm}
            type="secondary"
            size="smallIcon"
            className="h-fit w-fit min-w-fit rounded-full p-1 lg:h-fit lg:w-fit lg:min-w-fit">
            <MessageSquareDot className="text-korDarkGrey h-5 w-5" />
            <span className="text-korDarkGrey text-xs text-inherit" style={{ fontSize: 'x-small' }}>
              {hierarchyType == 'post' ? post.total_replies : post.child_messages}
            </span>
          </Button>
        )}
        {/* {hierarchyType === 'post' && (
          <Button
            onClick={(e) => onShare(e)}
            type="secondary"
            size="smallIcon"
            className="h-fit w-fit min-w-fit rounded-full p-1 lg:h-fit lg:w-fit lg:min-w-fit">
            <PostItemShare post={post} userType={userType} onSharePost={messageState.onSharePost} />
          </Button>
        )} */}
      </div>
      <div className="mt-4 flex items-start sm:items-center md:mt-0 relative" onClick={onReact}>
        <PostCommentReactions
          contextUserType={userType}
          message={post}
          query={query || messageState.query}
          triggerScoreAnimation={(value: number) => triggerScoreAnimation(value, 'reaction')}
        />
        <AnimatedScoreIncrease
          className={cn(
            'absolute right-0 text-green-500',
            scoreAnimation.position === 'reaction' && 'top-[-25px]',
          )}
          scoreAnimation={scoreAnimation}
          visiblePositions={['reaction']}
          userType={userType}
        />
      </div>
    </div>
  );
};

'use client';
import { useEffect, useId, useMemo } from 'react';
import {
  AdaptiveFlexLeftPane,
  AdaptiveFlexLeftPaneItem,
  AdaptiveFlexLeftPaneSearchAndButton,
  AdaptiveFlexPanel,
  AdaptiveFlexRightPane,
  Button,
  EmptyScreen,
  Icon,
  IConversationSet,
  useConversationContext,
  useMessageContext,
} from '@ui/components';
import { LeaderboardItemLoader } from '@ui/components/organisms/leaderboard/components/leaderboard-item';
import { CreateConversationModal } from './create-conversation-modal';
import { ConversationMessagesPane } from './conversation-messages-pane';
import InfiniteScroll from 'react-infinite-scroll-component';

export const ConversationAdaptivePanel = () => {
  const id = useId();
  const state = useConversationContext();
  const messageState = useMessageContext('creator');

  const emptyListMessage = useMemo(() => {
    if (Boolean(state.searchValue)) {
      return {
        title: `No results for "${state.searchValue}"`,
        message: 'Try using different keywords.',
      };
    }

    if (!state.conversationSets.length && !Boolean(state.searchValue)) {
      return {
        title: "You haven't created any messages yet",
        message: 'Start by creating your first message.',
      };
    }

    return {
      title: '',
      message: '',
    };
  }, [state.selectedConversationSet, state.conversationSets, state.searchValue]);

  useEffect(() => {
    if (
      state.conversationSets.length > 0 &&
      !state.isLoadingConversations &&
      !state.selectedConversationSet
    ) {
      const firstConversation = state.conversationSets[0];
      onSelectConversation(firstConversation);
    }
  }, [state.conversationSets, state.isLoadingConversations]);

  const onSelectConversation = (conversation: IConversationSet) => {
    state.onSelectConversation(conversation);
    messageState.onSetCreator(conversation.conversation.creator_id);
    messageState.onSetThread(conversation.conversation.main_thread);
    messageState.onSetConversation(conversation.conversation.id);
    messageState.onSetFan(conversation.conversation.creator_id);
    messageState.onSetConversationMiscellaneous(conversation.conversation?.miscellaneous);
  };

  return (
    <AdaptiveFlexPanel
      selectedItemId={state.selectedConversationSet?.conversation.id}
      className="h-full max-h-[74.6vh] overflow-hidden">
      <AdaptiveFlexLeftPane className="relative flex h-full w-full flex-col space-y-2 overflow-hidden overflow-x-hidden px-0 pt-2 md:w-1/4">
        <div className="sticky top-0 z-10 bg-white px-2">
          <AdaptiveFlexLeftPaneSearchAndButton
            onSearch={state.handleSearch}
            onAddButtonClick={state.onAddConversation}
            placeholder="Search messages..."
            customButton={
              <CreateConversationModal {...state}>
                <Button className="min-w-fit rounded" type="primary" size="smallIcon">
                  <Icon type="plus" />
                </Button>
              </CreateConversationModal>
            }
          />
        </div>

        {state.isLoadingConversations && (
          <div className="flex flex-col gap-4 p-2">
            <LeaderboardItemLoader />
            <LeaderboardItemLoader />
            <LeaderboardItemLoader />
          </div>
        )}

        {!state.isLoadingConversations && !state.conversationSets.length && state.isSuccess && (
          <EmptyScreen
            title={emptyListMessage.title}
            message={emptyListMessage.message}
            button={<CreateConversationModal>Create message</CreateConversationModal>}
            onClick={state.onAddConversation}
            className="flex h-full min-h-full items-baseline justify-center rounded-none px-2 py-10 lg:py-10"
          />
        )}

        <div id={id} className="relative mt-4 h-full max-h-[calc(68vh)] overflow-y-auto">
          <InfiniteScroll
            className="h-full space-y-2 px-2 pb-20"
            dataLength={state.conversationSets.length}
            next={state.onNextPage}
            hasMore={state.hasMorePages}
            scrollableTarget={id}
            loader={<LeaderboardItemLoader />}>
            {state.conversationSets.map((item) => (
              <AdaptiveFlexLeftPaneItem
                key={item.conversation.id}
                itemId={item.conversation.id}
                onClick={() => onSelectConversation(item)}>
                <div className="flex w-full items-center justify-between gap-2 overflow-hidden">
                  <div>
                    <span className="text-md line-clamp-1 truncate whitespace-pre-wrap font-medium">
                      {item.conversation.name}
                    </span>
                    {item?.text_of_last_message && item.user_name_last_message && (
                      <div
                        style={{ lineHeight: 'normal' }}
                        className="text-note flex flex-row items-center gap-1">
                        <span className="text-note text-xs">
                          {item.conversation.creator_id === state.creator_id
                            ? 'You'
                            : item?.user_name_last_message}
                        </span>
                        :
                        <span className="text-note line-clamp-1 truncate whitespace-pre-wrap text-xs">
                          {item?.text_of_last_message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </AdaptiveFlexLeftPaneItem>
            ))}
          </InfiniteScroll>
        </div>
      </AdaptiveFlexLeftPane>

      <AdaptiveFlexRightPane className="h-full overflow-hidden p-0 md:w-3/4">
        {state.selectedConversationSet && (
          <ConversationMessagesPane conversationSet={state.selectedConversationSet} />
        )}
      </AdaptiveFlexRightPane>
    </AdaptiveFlexPanel>
  );
};

'use client';
import React, { useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import { twMerge } from 'tailwind-merge';

interface TextWithLinksProps {
  text: string;
  amountOfWords?: number;
  isSinglePost?: boolean;
  isComment?: boolean;
  useCase?: string;
  alwaysShowFullText?: boolean;
  preventClickThrough?: boolean;
  className?: string;
}

const TextWithLinksComponent: React.FC<TextWithLinksProps> = ({
  text,
  amountOfWords = 66,
  isSinglePost = false,
  isComment,
  useCase,
  alwaysShowFullText = false,
  preventClickThrough,
  className,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullText, setShowFullText] = useState(alwaysShowFullText);
  const [beginText, setBeginText] = useState('');
  const [endText, setEndText] = useState('');

  // Add DOMPurify configuration constant
  const SANITIZE_CONFIG = {
    FORBID_TAGS: [
      'script',
      'iframe',
      'style',
      'form',
      'input',
      'button',
      'object',
      'embed',
      'base',
    ],
    FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover', 'onmouseout', 'onfocus', 'onblur'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'class', 'style'],
  };

  let renderText = React.useMemo(() => {
    if (!text) return '';

    // Normalize whitespace
    let normalizedText = text.replace(/\s+/g, ' ').trim();

    // Process markdown-style links
    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/g;
    let modifiedText = normalizedText.replace(markdownLinkRegex, (match, linkText, url) => {
      let fullUrl = url;
      if (!/^https?:\/\//i.test(fullUrl)) {
        fullUrl = `https://${fullUrl}`;
      }
      return `<a href="${fullUrl}" target="_blank" rel="noopener noreferrer" class="text-primary">${linkText}</a>`;
    });

    // Process plain URLs
    const urlRegex = /(?![^<]*>)(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/gi;
    modifiedText = modifiedText.replace(urlRegex, (match) => {
      const isEmail = match.includes('@');
      if (isEmail) {
        return `<a href="mailto:${match}">${match}</a>`;
      } else {
        let url = match;
        if (!/^https?:\/\//i.test(url)) {
          url = `https://${url}`;
        }
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" class="text-primary">${match}</a>`;
      }
    });

    return modifiedText;
  }, [text]);

  useEffect(() => {
    if (!renderText) {
      setBeginText('');
      setEndText('');
      setIsTruncated(false);
      return;
    }

    if (!isSinglePost && !alwaysShowFullText) {
      // Split the text by spaces, but ensure HTML tags are not broken
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = renderText;
      const textContent = tempDiv.textContent || tempDiv.innerText || '';
      const splittedText = textContent.split(' ').filter(Boolean);
      const isTruncatedNow = splittedText.length > amountOfWords;

      if (isTruncatedNow) {
        const beginWords = splittedText.slice(0, amountOfWords - 1).join(' ');
        const endWords = splittedText.slice(amountOfWords - 1).join(' ');

        // Reconstruct the HTML for beginText and endText
        tempDiv.innerHTML = renderText;
        const allWords = tempDiv.innerHTML.split(' ');
        const beginHTML = allWords.slice(0, amountOfWords - 1).join(' ');
        const endHTML = allWords.slice(amountOfWords - 1).join(' ');

        setBeginText(showFullText ? beginHTML : beginHTML + '...');
        setEndText(endHTML);
      } else {
        setBeginText(renderText);
        setEndText('');
      }
      setIsTruncated(isTruncatedNow);
    } else {
      setIsTruncated(false);
      setBeginText(renderText);
      setEndText('');
    }
  }, [renderText, amountOfWords, showFullText, isSinglePost, alwaysShowFullText]);

  const handleClick = (e: any) => {
    if (preventClickThrough) {
      e?.preventDefault();
      e?.stopPropagation();
    }

    if (!alwaysShowFullText) {
      if (useCase === 'posts') {
        !isSinglePost && isComment && setShowFullText(!showFullText);
      } else if (useCase === 'messaging') {
        setShowFullText(!showFullText);
      }
    }
  };

  return (
    <p className={twMerge('editor-content', className)}>
      <span
        className="ProseMirror"
        style={{ lineHeight: '1.5', transition: 'max-height 0.3s ease-out' }}>
        <span
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(beginText, SANITIZE_CONFIG),
          }}
        />
        {isTruncated && !alwaysShowFullText && (
          <>
            {showFullText && (
              <span
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(endText, SANITIZE_CONFIG),
                }}
              />
            )}
            <span className="text-primary cursor-pointer" onClick={handleClick}>
              {showFullText ? 'see less' : 'read more'}
            </span>
          </>
        )}
      </span>
    </p>
  );
};

export const TextWithLinks = React.memo(TextWithLinksComponent);

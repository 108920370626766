import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@ui/components/ui/dialog';
import { Input } from '@ui/components';
import { Button } from '@ui/components';
import DOMPurify from 'dompurify';

const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-@./?%&=]*)?$/;

export interface ContentEditableRef extends HTMLDivElement {
  clear?: () => void;
  openLinkDialog?: () => void;
}

interface ContentEditableProps {
  ref2?: React.RefObject<ContentEditableRef>; // Update the ref type
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
  contentEditableClassName?: string;
  placeholderClassName?: string;
  charsCounterClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  updatedContent?: string;
  maxLength?: number;
  autoFocus?: boolean;
  onChange?: (content: string) => void;
  onKeyUp?: (e: React.KeyboardEvent) => void;
  onKeyDown?: (e: React.KeyboardEvent) => void;
  onFocus?: (e: React.FocusEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onContentExternalUpdate?: (content: string) => void;
  onLinkInserted?: () => void;
}

// Helper function to check if content length is within maxLength
const isContentWithinMaxLength = (content: string, maxLength?: number): boolean => {
  if (!maxLength) return true;
  return content.length <= maxLength;
};

const ContentEditable: React.FC<ContentEditableProps> = ({
  ref2,
  containerClassName,
  contentEditableClassName,
  placeholderClassName,
  charsCounterClassName,
  placeholder,
  disabled,
  updatedContent,
  maxLength,
  autoFocus,
  onChange,
  onKeyUp,
  onKeyDown,
  onFocus,
  onBlur,
  onLinkInserted,
  containerStyle,
}) => {
  const [content, setContent] = useState('');
  const divRef = useRef<HTMLDivElement | null>(null);
  const [showLinkDialog, setShowLinkDialog] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');
  const [hasSelection, setHasSelection] = useState(false);
  const [linkError, setLinkError] = useState('');
  const [selectedLink, setSelectedLink] = useState<HTMLAnchorElement | null>(null);
  const [showEditPopover, setShowEditPopover] = useState(false);
  const [savedRange, setSavedRange] = useState<Range | null>(null);

  // Safe mobile detection - won't break SSR
  const isMobile = useMemo(() => {
    if (typeof window === 'undefined') return false;
    return /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  }, []);

  useEffect(() => {
    if (divRef.current && updatedContent !== undefined) {
      divRef.current.innerHTML = updatedContent;
      setContent(updatedContent);
    }
  }, []);

  useEffect(() => {
    if (ref2 && divRef.current) {
      // @ts-ignore - we're allowing external access to the div
      ref2.current = divRef.current;
      // @ts-ignore - add methods
      ref2.current.clear = () => {
        if (divRef.current) {
          divRef.current.innerHTML = '';
          setContent('');
          if (onChange) onChange('');
        }
      };
    }
  }, [ref2, onChange]);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.style.height = 'auto';
      if (onChange && isContentWithinMaxLength(content, maxLength)) {
        onChange(content);
      }
    }
  }, [content, maxLength]);

  useEffect(() => {
    if (divRef.current && autoFocus) {
      divRef.current.focus();
    }
  }, [autoFocus]);

  /**
   * Checks if the caret is on the last line of a contenteditable element
   * @param element - The HTMLDivElement to check
   * @returns A boolean indicating whether the caret is on the last line or `false` when the caret is part of a selection
   */
  const isCaretOnLastLine = useCallback((element: HTMLDivElement): boolean => {
    if (element.ownerDocument.activeElement !== element) return false;

    // Get the client rect of the current selection
    const window = element.ownerDocument.defaultView;

    if (!window) return false;

    const selection = window.getSelection();

    if (!selection || selection.rangeCount === 0) return false;

    const originalCaretRange = selection.getRangeAt(0);

    // Bail if there is a selection
    if (originalCaretRange.toString().length > 0) return false;

    const originalCaretRect = originalCaretRange.getBoundingClientRect();

    // Create a range at the end of the last text node
    const endOfElementRange = document.createRange();
    endOfElementRange.selectNodeContents(element);

    // The endContainer might not be an actual text node,
    // try to find the last text node inside
    let endContainer = endOfElementRange.endContainer;
    let endOffset = 0;

    while (endContainer.hasChildNodes() && !(endContainer instanceof Text)) {
      if (!endContainer.lastChild) continue;

      endContainer = endContainer.lastChild;
      endOffset = endContainer instanceof Text ? endContainer.length : 0;
    }

    endOfElementRange.setEnd(endContainer, endOffset);
    endOfElementRange.setStart(endContainer, endOffset);
    const endOfElementRect = endOfElementRange.getBoundingClientRect();

    return originalCaretRect.bottom === endOfElementRect.bottom;
  }, []);

  /**
   * Handles the caret scroll behavior based on keyboard events
   * @param e - The keyboard event
   */
  const handleCaretScroll = useCallback(
    (e: KeyboardEvent) => {
      if (!divRef.current) return;
      const focus = divRef.current;
      switch (e.keyCode) {
        case 38:
          if ((focus as any).selectionStart === 0) focus.scrollTop = 0;
          break;
        case 13:
        case 40:
          if (isCaretOnLastLine(focus)) focus.scrollTop = focus.scrollHeight;
          break;
        default:
          break;
      }
    },
    [isCaretOnLastLine],
  );

  function handlePasteEvent(e: React.ClipboardEvent<HTMLDivElement>) {
    e.preventDefault();

    const clipboardData = e.clipboardData || (window as any).clipboardData;
    const plainText = clipboardData.getData('text/plain');
    const htmlText = clipboardData.getData('text/html');

    const sel: Selection | null = window.getSelection();
    const currentContent = divRef.current?.innerHTML || '';

    if (sel && sel.rangeCount) {
      const range = sel.getRangeAt(0);
      const selectedText = range.toString();

      // Create a temporary container and clean the HTML
      const tempDiv = document.createElement('div');
      if (htmlText) {
        // First pass: Basic sanitization with limited tags
        const sanitizedHtml = DOMPurify.sanitize(htmlText, {
          ALLOWED_TAGS: ['a', 'b', 'i', 'em', 'strong', 'p', 'br', 'div', 'span'],
          ALLOWED_ATTR: ['href', 'target', 'rel'],
        });

        tempDiv.innerHTML = sanitizedHtml;
      } else {
        // Handle plain text with proper line breaks
        const formattedText = plainText
          .split('\n')
          .map((line) => {
            // Preserve multiple spaces using nbsp
            const spacedLine = line.replace(/ {2,}/g, (match) => '\u00A0'.repeat(match.length));
            return line.trim() ? `<div>${spacedLine}</div>` : '<br>';
          })
          .join('');
        tempDiv.innerHTML = formattedText;
      }

      // Second pass: Clean up nested structures and empty elements
      const cleanupNode = (node: Node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          const element = node as HTMLElement;

          // Remove all style attributes
          element.removeAttribute('style');
          element.removeAttribute('class');

          // Handle empty elements
          if (element.tagName !== 'BR') {
            if (!element.textContent?.trim() && !element.querySelector('br')) {
              if (element.tagName === 'DIV' || element.tagName === 'P') {
                // Replace empty block elements with br
                const br = document.createElement('br');
                element.parentNode?.replaceChild(br, element);
                return;
              } else {
                element.parentNode?.removeChild(element);
                return;
              }
            }
          }

          // Clean up nested elements of the same type
          if (element.children.length > 0) {
            const tagName = element.tagName;
            Array.from(element.children).forEach((child) => {
              if (child.tagName === tagName) {
                while (child.firstChild) {
                  element.insertBefore(child.firstChild, child);
                }
                element.removeChild(child);
              }
            });
          }
        }

        // Recursively clean up child nodes
        Array.from(node.childNodes).forEach(cleanupNode);
      };

      cleanupNode(tempDiv);

      const textLength = tempDiv.textContent?.length || 0;
      const availableSpace = maxLength
        ? maxLength - (currentContent.length - selectedText.length)
        : textLength;

      if (availableSpace > 0) {
        range.deleteContents();

        if (maxLength && textLength > availableSpace) {
          let currentLength = 0;
          const truncateNode = (node: Node): boolean => {
            if (currentLength >= availableSpace) return true;

            if (node.nodeType === Node.TEXT_NODE) {
              const text = node.textContent || '';
              const remainingSpace = availableSpace - currentLength;
              if (text.length > remainingSpace) {
                node.textContent = text.slice(0, remainingSpace);
                currentLength += remainingSpace;
                return true;
              }
              currentLength += text.length;
            } else {
              const children = Array.from(node.childNodes);
              for (const child of children) {
                if (truncateNode(child)) {
                  while (child.nextSibling) {
                    child.parentNode?.removeChild(child.nextSibling);
                  }
                  return true;
                }
              }
            }
            return false;
          };

          truncateNode(tempDiv);
        }

        // Insert the cleaned content
        const fragment = document.createDocumentFragment();
        while (tempDiv.firstChild) {
          fragment.appendChild(tempDiv.firstChild);
        }
        range.insertNode(fragment);

        // Move cursor to end of inserted content
        range.collapse(false);
        sel.removeAllRanges();
        sel.addRange(range);

        if (divRef.current) {
          setContent(divRef.current.innerHTML);
        }
      }
    }
  }

  /**
   * Inserts the specified text at the current caret position in the contentEditable element
   * @param text - The text to be inserted
   */
  function insertTextAtCaret(text: string) {
    if (!divRef.current) return;
    const currentCaretPos = getCaretPosition(divRef.current);

    divRef.current.innerHTML =
      divRef.current.innerHTML.slice(0, currentCaretPos) +
      text +
      divRef.current.innerHTML.slice(currentCaretPos);

    setContent(divRef.current.innerHTML);
    divRef.current.scrollTop = divRef.current.scrollHeight;
    setCaretPosition(divRef.current, currentCaretPos + text.length);
  }

  // Note: setSelectionRange and createTextRange are not supported by contenteditable elements

  /**
   * Sets the caret position within the contentEditable element
   * If the element is empty, it will be focused
   *
   * @param elem - The contentEditable element
   * @param pos - The position to set the caret to
   */
  function setCaretPosition(elem: HTMLElement, pos: number) {
    // Create a new range
    const range = document.createRange();

    // Get the child node of the div
    const childNode = elem.childNodes[0];

    if (childNode != null) {
      // Set the range to the correct position within the text
      range.setStart(childNode, pos);
      range.setEnd(childNode, pos);

      // Get the selection object
      const sel: Selection | null = window.getSelection();
      if (!sel) return;
      // Remove any existing selections
      sel.removeAllRanges();

      // Add the new range (this will set the cursor position)
      sel.addRange(range);
    } else {
      // If the div is empty, focus it
      elem.focus();
    }
  }

  /**
   * Sets the caret (text cursor) position at the end of the specified contenteditable element
   * @param editableDiv - The HTMLElement representing the contenteditable div where the caret should be placed
   */
  function setCaretAtTheEnd(editableDiv: HTMLElement) {
    const range = document.createRange();
    const sel = window.getSelection();
    if (editableDiv.lastChild && sel) {
      range.setStartAfter(editableDiv.lastChild);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }

  /**
   * Retrieves the caret position within the contentEditable element
   * @param editableDiv - The contentEditable element
   * @returns The caret position as a number
   */
  function getCaretPosition(editableDiv: HTMLElement) {
    let caretPos = 0,
      range;
    if (window.getSelection) {
      const sel: Selection | null = window.getSelection();
      if (sel && sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode === editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.getSelection() && document.getSelection()?.getRangeAt) {
      range = document.getSelection()?.getRangeAt(0);
      if (range && range.commonAncestorContainer.parentNode === editableDiv) {
        const tempEl = document.createElement('span');
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        const tempRange: any = range.cloneRange();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint('EndToEnd', range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (onKeyDown) onKeyDown(e);
    if (!divRef.current) return;
    if (
      (['Delete', 'Backspace'].includes(e.key) && isAllTextSelected()) ||
      (e.key === 'Backspace' && content.length === 1) ||
      (e.key === 'Delete' && getCaretPosition(divRef.current) === 0 && content.length === 1)
    ) {
      e.preventDefault();

      divRef.current.innerHTML = '';
      setContent('');
    }
  }

  const isAllTextSelected = (): boolean => {
    const sel: Selection | null = window.getSelection();

    // Matches newline characters that are either followed by another newline
    // character (\n) or the end of the string ($).
    const newlineCount = (divRef.current?.innerHTML.match(/\n(\n|$)/g) || []).length;
    return sel ? sel.toString().length + newlineCount === divRef.current?.innerHTML.length : false;
  };

  useEffect(() => {
    document.addEventListener('keyup', handleCaretScroll);
    return () => document.removeEventListener('keyup', handleCaretScroll);
  }, [handleCaretScroll]);

  const getSelectedText = useCallback(() => {
    const selection = window.getSelection();
    return selection?.toString() || '';
  }, []);

  const openLinkDialog = useCallback(() => {
    const selection = window.getSelection();
    const selectedText = selection?.toString() || '';

    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (divRef.current?.contains(range.commonAncestorContainer) && selectedText.length > 0) {
        setSavedRange(range.cloneRange()); // Save a copy of the range
        setHasSelection(true);
        setLinkText(selectedText);
      } else {
        setSavedRange(null);
        setHasSelection(false);
        setLinkText('');
      }
    } else {
      setSavedRange(null);
      setHasSelection(false);
      setLinkText('');
    }

    setShowLinkDialog(true);
  }, []);

  const closeDialog = useCallback(() => {
    setShowLinkDialog(false);
    setLinkUrl('');
    setLinkText('');
    setLinkError('');
    setHasSelection(false);
    setSavedRange(null);
    divRef.current?.focus();
  }, []);

  const handleLinkDialogKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLInputElement) {
        e.preventDefault();
        if (linkUrl && (hasSelection || linkText) && !linkError) {
          insertLink();
        }
      }
    },
    [linkUrl, linkText, hasSelection, linkError],
  );

  const handleLinkClick = useCallback((e: MouseEvent) => {
    const target = e.target as HTMLElement;
    if (target.tagName === 'A') {
      e.preventDefault();
      setSelectedLink(target as HTMLAnchorElement);
      setShowEditPopover(true);
    } else {
      setShowEditPopover(false);
      setSelectedLink(null);
    }
  }, []);

  const handleEditLink = useCallback(() => {
    if (!selectedLink) return;

    const href = selectedLink.href;
    const url = href.startsWith('http') ? href : href.replace(window.location.origin, '');

    setLinkUrl(url);
    setLinkText(selectedLink.textContent || '');
    setHasSelection(false);
    setShowEditPopover(false);
    setShowLinkDialog(true);
  }, [selectedLink]);

  const handleRemoveLink = useCallback(() => {
    if (!selectedLink || !divRef.current) return;

    const textNode = document.createTextNode(selectedLink.textContent || '');
    selectedLink.parentNode?.replaceChild(textNode, selectedLink);

    setContent(divRef.current.innerHTML);
    if (onChange) {
      onChange(divRef.current.innerHTML);
    }

    setShowEditPopover(false);
    setSelectedLink(null);
  }, [selectedLink, onChange]);

  const insertLink = useCallback(() => {
    if (!divRef.current || !linkUrl) return;

    const formattedUrl = linkUrl.match(/^https?:\/\//) ? linkUrl : `https://${linkUrl}`;

    const selection = window.getSelection();
    if (!selection) return;

    const linkElement = document.createElement('a');
    linkElement.href = formattedUrl;
    linkElement.target = '_blank';
    linkElement.rel = 'noopener noreferrer';
    linkElement.className = 'text-primary hover:underline cursor-pointer';
    linkElement.textContent = linkText || formattedUrl;

    let insertedLink: HTMLAnchorElement | null = null;

    if (selectedLink) {
      selectedLink.parentNode?.replaceChild(linkElement, selectedLink);
      insertedLink = linkElement;
      setSelectedLink(null);
    } else if (hasSelection && savedRange) {
      selection.removeAllRanges();
      selection.addRange(savedRange);
      savedRange.deleteContents();
      savedRange.insertNode(linkElement);
      insertedLink = linkElement;
    } else {
      if (savedRange) {
        savedRange.insertNode(linkElement);
        insertedLink = linkElement;
      } else {
        divRef.current.appendChild(linkElement);
        insertedLink = linkElement;
      }
    }

    if (insertedLink && insertedLink.parentNode) {
      const range = document.createRange();
      range.setStartAfter(insertedLink);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
    }

    setContent(divRef.current.innerHTML);
    if (onChange) {
      onChange(divRef.current.innerHTML);
    }

    setSavedRange(null);
    onLinkInserted?.();
    closeDialog();
  }, [
    linkUrl,
    linkText,
    hasSelection,
    closeDialog,
    onLinkInserted,
    onChange,
    selectedLink,
    savedRange,
  ]);

  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener('click', handleLinkClick);
      return () => div.removeEventListener('click', handleLinkClick);
    }
  }, [handleLinkClick]);

  useEffect(() => {
    if (ref2 && divRef.current) {
      // @ts-ignore - we're allowing external access to the div
      ref2.current = divRef.current;
      // @ts-ignore - add methods
      ref2.current.clear = () => {
        if (divRef.current) {
          divRef.current.innerHTML = '';
          setContent('');
          if (onChange) onChange('');
        }
      };
      ref2.current.openLinkDialog = openLinkDialog;
    }
  }, [ref2, onChange, openLinkDialog]);

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const currentContent = e.currentTarget.innerHTML;

    if (disabled) {
      if (divRef.current) {
        divRef.current.innerHTML = content;
        setCaretAtTheEnd(divRef.current);
      }
      return;
    }

    if (!isContentWithinMaxLength(currentContent, maxLength)) {
      if (divRef.current) {
        divRef.current.innerHTML = content;
        setCaretAtTheEnd(divRef.current);
      }
      return;
    }

    // Force immediate DOM update on mobile
    if (isMobile && divRef.current) {
      divRef.current.innerHTML = currentContent;
      const len = currentContent.length;

      // Force selection to end of content
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(divRef.current);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }

    setContent(currentContent);
  };

  // Add a blur handler to force update
  const handleBlur = () => {
    if (isMobile && divRef.current) {
      const currentContent = divRef.current.innerHTML;
      divRef.current.innerHTML = currentContent;
    }
  };

  return (
    <>
      {selectedLink && showEditPopover && (
        <div className="absolute z-50 bg-white shadow-lg rounded-md border border-gray-200">
          <div className="flex items-center gap-2 p-2">
            <Button
              onClick={handleEditLink}
              type="secondary"
              className="justify-start text-xs m-0 p-0 px-1 rounded w-fit min-w-fit"
              size="small">
              Change
            </Button>
            <Button
              onClick={handleRemoveLink}
              type="secondary"
              className="justify-start text-xs m-0 p-0 px-1 rounded w-fit min-w-fit"
              size="small">
              Remove
            </Button>
          </div>
        </div>
      )}

      <div
        className={twMerge(containerClassName, 'content-editable-container')}
        style={{ display: 'flex', alignItems: 'center', position: 'relative', ...containerStyle }}>
        <div
          ref={divRef}
          contentEditable
          defaultValue={content}
          aria-disabled={disabled}
          dir="auto"
          role="textbox"
          aria-label={placeholder ?? ''}
          className={contentEditableClassName}
          style={{
            overflow: 'auto',
            height: 'auto',
            textAlign: 'initial',
            wordBreak: 'break-word',
            unicodeBidi: 'plaintext',
            // color: '#000000',
            caretColor: '#000000',
            // WebkitTextFillColor: '#000000',
            opacity: 1,
            background: 'transparent',
          }}
          onInput={handleInput}
          onPaste={(e) => {
            if (disabled) return;
            handlePasteEvent(e);
          }}
          onFocus={(e) => {
            if (onFocus) onFocus(e);
          }}
          onBlur={handleBlur}
          onKeyUp={(e) => {
            if (disabled) return;
            if (onKeyUp) onKeyUp(e);
          }}
          onKeyDown={(e) => {
            if (disabled) return;
            handleKeyDown(e);
          }}
          {...(isMobile
            ? {
                autoCapitalize: 'off',
                autoCorrect: 'off',
                spellCheck: false,
              }
            : {})}
        />
        {!divRef.current?.innerHTML && (
          <span
            dir="auto"
            className={twMerge(placeholderClassName)}
            style={{
              position: 'absolute',
              pointerEvents: 'none',
              textAlign: 'initial',
            }}>
            {placeholder ?? ''}
          </span>
        )}
        {!!maxLength && (
          <span
            dir="auto"
            className={charsCounterClassName}
            style={{
              marginLeft: '1rem',
            }}>
            {`${content.length ?? 0}/${maxLength}`}
          </span>
        )}
      </div>

      <Dialog open={showLinkDialog} onOpenChange={(open) => !open && closeDialog()}>
        <DialogContent className="max-w-[320px] w-full bg-white shadow">
          <DialogHeader>
            <DialogTitle>Insert Link</DialogTitle>
          </DialogHeader>
          <div className="space-y-2">
            {/* Always show text input, pre-filled with selection if exists */}
            <Input
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
              className="w-full"
              placeholder="Text"
              onKeyDown={handleLinkDialogKeyDown}
            />
            <Input
              value={linkUrl}
              onChange={(e) => {
                const url = e.target.value;
                setLinkUrl(url);
                setLinkError(urlPattern.test(url) ? '' : 'Please enter a valid URL');
              }}
              className="w-full"
              placeholder="Type or paste a link"
              error={linkError}
              onKeyDown={handleLinkDialogKeyDown}
            />
          </div>
          <DialogFooter>
            <Button
              size="small"
              onClick={insertLink}
              disabled={!linkUrl || !linkText || !!linkError}
              type={'primary'}>
              Apply
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ContentEditable;

'use client';
import { Button, Icon } from '@ui/components';
import { twMerge } from 'tailwind-merge';
import { useRouter } from 'next/navigation';
import logo from '../../assets/images/Logo-text-dark.png';

interface IMobileBackButton {
  isDark?: boolean;
  onBack?(): void;
  backStroke?: string;
  showButton?: boolean;
}

export function MobileHeaderBackButton({
  isDark = false,
  onBack,
  backStroke,
  showButton = true,
}: IMobileBackButton) {
  const router = useRouter();
  return showButton ? (
    <div className="flex items-center gap-4">
      <Button
        type="icon"
        className={twMerge(
          'bg-bgGrey hover:bg-inputActive h-12 w-12 border-transparent',
          isDark
            ? 'border-transparent bg-transparent hover:border-white hover:bg-transparent active:border-white active:bg-transparent'
            : '',
        )}
        onClick={onBack}>
        <Icon type="backArrow" stroke={backStroke || isDark ? '#fff' : undefined} />
      </Button>
      <div className="text-sm font-medium underline" onClick={() => router.push('/')}>
        <img src={logo.src} alt="logo" className="object-contain w-24 h-6" />
      </div>
    </div>
  ) : (
    <div className="h-12 w-12" />
  );
}

import { Editor } from '@tiptap/react';
import { IFile } from '@ui/hooks/file/types';
import { Bold, Italic, Strikethrough, List, ListOrdered, Image, Link } from 'lucide-react';
import { Toggle, Separator } from '@ui/components/ui';
import { InputFileWithTrigger } from '@ui/components/moleculas';
import { twMerge } from 'tailwind-merge';
import { EmailToggle } from './send-email-toggle';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@ui/components/ui/dialog';
import { Input, Button } from '@ui/components';
import { useState, useEffect } from 'react';

interface RichTextEditorToolbarProps {
  editor: Editor;
  exceededFileLimit: boolean;
  files: IFile[];
  maxFiles: number;
  hideFileUpload?: boolean;
  minimized?: boolean;
  showLabel?: boolean;
  onSelectFiles?: (fileList: FileList) => void;
  sendEmail?: boolean;
  onToggleSendEmail?: (value: boolean) => void;
  onLinkClick?: () => void;
}

const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-@./?%&=]*)?$/;

export const RichTextEditorToolbarList = (props: RichTextEditorToolbarProps) => {
  const [showLinkDialog, setShowLinkDialog] = useState(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');
  const [linkError, setLinkError] = useState('');

  const handleLinkClick = () => {
    // Get selected text from editor
    const selectedText = props.editor.state.doc.textBetween(
      props.editor.state.selection.from,
      props.editor.state.selection.to,
    );

    // If there's a link at cursor, get its details for editing
    if (props.editor.isActive('link')) {
      const linkAttrs = props.editor.getAttributes('link');
      setLinkUrl(linkAttrs.href || '');
      setLinkText(selectedText || linkAttrs.text || '');
    } else {
      setLinkUrl('');
      setLinkText(selectedText || '');
    }

    setShowLinkDialog(true);
  };

  const insertLink = () => {
    if (!linkUrl) return;

    const formattedUrl = linkUrl.match(/^https?:\/\//) ? linkUrl : `https://${linkUrl}`;

    if (linkText) {
      // If we have text, create a link with that text
      props.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: formattedUrl })
        .insertContent(linkText)
        .run();
    } else {
      // Just set the link on existing selection
      props.editor.chain().focus().extendMarkRange('link').setLink({ href: formattedUrl }).run();
    }

    closeDialog();
  };

  const closeDialog = () => {
    setShowLinkDialog(false);
    setLinkUrl('');
    setLinkText('');
    setLinkError('');
  };

  const handleLinkDialogKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLInputElement) {
      e.preventDefault();
      if (linkUrl && !linkError) {
        insertLink();
      }
    }
  };

  const toolbars = [
    <Toggle
      key="toolbar-bold"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('bold')}
      onPressedChange={() => props.editor.chain().focus().toggleBold().run()}>
      <Bold className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Bold</span>}
    </Toggle>,
    <Toggle
      key="toolbar-italic"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('italic')}
      onPressedChange={() => props.editor.chain().focus().toggleItalic().run()}>
      <Italic className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Italic</span>}
    </Toggle>,
    <Toggle
      key="toolbar-strikethrough"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('strike')}
      onPressedChange={() => props.editor.chain().focus().toggleStrike().run()}>
      <Strikethrough className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Strikethrough</span>}
    </Toggle>,
    ...(!props.minimized
      ? [
          <Separator
            key="toolbar-Separator"
            orientation="vertical"
            className="h-8 w-[1px] hidden md:block"
          />,
        ]
      : []),
    <Toggle
      key="toolbar-bulletList"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('bulletList')}
      onPressedChange={() => props.editor.chain().focus().toggleBulletList().run()}>
      <List className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">BulletList</span>}
    </Toggle>,
    <Toggle
      key="toolbar-orderedList"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('orderedList')}
      onPressedChange={() => props.editor.chain().focus().toggleOrderedList().run()}>
      <ListOrdered className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Ordered List</span>}
    </Toggle>,
    <Toggle
      key="toolbar-link"
      className={twMerge(
        'flex flex-row items-center justify-start',
        props.minimized ? 'hidden' : '',
      )}
      size="sm"
      pressed={props.editor.isActive('link')}
      onPressedChange={() => props.onLinkClick?.()}>
      <Link className="h-4 w-4" />
      {props.showLabel && <span className="ml-4">Link</span>}
    </Toggle>,
  ];

  if (!props.hideFileUpload) {
    toolbars.push(
      <InputFileWithTrigger
        key="toolbar-Image"
        className="flex h-[32px] items-center"
        maxFiles={props.maxFiles}
        exceededFileLimit={props.files.length >= props.maxFiles}
        onSelectFiles={props.onSelectFiles}>
        <Toggle size="sm" pressed={false}>
          <Image className="h-4 w-4" />
          {props.showLabel && <span className="ml-4">Image</span>}
        </Toggle>
      </InputFileWithTrigger>,
    );

    if (!props.minimized && props.onToggleSendEmail) {
      toolbars.push(
        <div className="flex items-center ml-2">
          <EmailToggle
            value={props.sendEmail || false}
            setValue={props.onToggleSendEmail}
            label={'Notify audience'}
          />
        </div>,
      );
    }
  }

  return toolbars as JSX.Element[];
};

export const RichTextEditorLinkDialog = (props: {
  editor: Editor;
  showLinkDialog: boolean;
  onClose: () => void;
}) => {
  const [linkUrl, setLinkUrl] = useState('');
  const [linkText, setLinkText] = useState('');
  const [linkError, setLinkError] = useState('');

  useEffect(() => {
    if (props.showLinkDialog) {
      // Get selected text from editor
      const selectedText = props.editor.state.doc.textBetween(
        props.editor.state.selection.from,
        props.editor.state.selection.to,
      );

      // If there's a link at cursor, get its details for editing
      if (props.editor.isActive('link')) {
        const linkAttrs = props.editor.getAttributes('link');
        setLinkUrl(linkAttrs.href || '');
        setLinkText(selectedText || linkAttrs.text || '');
      } else {
        setLinkUrl('');
        setLinkText(selectedText || '');
      }
    }
  }, [props.showLinkDialog, props.editor]);

  const insertLink = () => {
    if (!linkUrl) return;

    const formattedUrl = linkUrl.match(/^https?:\/\//) ? linkUrl : `https://${linkUrl}`;
    const hasSelection = !props.editor.state.selection.empty;

    if (linkText && !hasSelection) {
      // If we have text to insert and no selection, insert text and make it a link
      props.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: 'text',
            marks: [{ type: 'link', attrs: { href: formattedUrl } }],
            text: linkText,
          },
        ])
        .run();
    } else {
      // If we have a selection, just apply the link to it
      props.editor.chain().focus().setLink({ href: formattedUrl }).run();
    }

    props.onClose();
  };

  const handleLinkDialogKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey && e.target instanceof HTMLInputElement) {
      e.preventDefault();
      if (linkUrl && !linkError) {
        insertLink();
      }
    }
  };

  return (
    <Dialog open={props.showLinkDialog} onOpenChange={(open) => !open && props.onClose()}>
      <DialogContent className="max-w-[320px] w-full bg-white shadow">
        <DialogHeader>
          <DialogTitle>Insert Link</DialogTitle>
        </DialogHeader>
        <div className="space-y-2">
          <Input
            value={linkText}
            onChange={(e) => setLinkText(e.target.value)}
            className="w-full"
            placeholder="Text"
            onKeyDown={handleLinkDialogKeyDown}
          />
          <Input
            value={linkUrl}
            onChange={(e) => {
              const url = e.target.value;
              setLinkUrl(url);
              setLinkError(urlPattern.test(url) ? '' : 'Please enter a valid URL');
            }}
            className="w-full"
            placeholder="Type or paste a link"
            error={linkError}
            onKeyDown={handleLinkDialogKeyDown}
          />
        </div>
        <DialogFooter>
          <Button
            size="small"
            onClick={insertLink}
            disabled={!linkUrl || !!linkError}
            type={'primary'}>
            Apply
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

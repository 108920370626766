'use client';
import { Avatar, Text } from '@ui/components/atoms';
import { IMessage, TMessageAction } from '../../message.typings';
import { MessageItemDescription } from './message-item-description';
import { MessageItemFiles } from './message-item-files';
import { MessageItemReactions } from './message-item-reactions';
import { useMemo, useState, useCallback } from 'react';
import { formatMessageDate } from '../../message.constants';
import { MessageItemReactionPanel } from './message-item-reaction-panel';
import { MessageItemWrapper } from './message-item-wrapper';
import { IConversationRestrictionOptions } from '@ui/components/organisms/conversation';
import { getBadgeInfo } from '@ui/lib/utils';
import { Badge } from '@ui/components/organisms/badge';
import { MessageItemDropdown } from './message-item-dropdown';
import { twMerge } from 'tailwind-merge';

interface IProps {
  message: IMessage;
  isCreator: boolean;
  restrictions: IConversationRestrictionOptions | undefined;
  user_id: string;
  setSelectedMessage: (message: IMessage, action: TMessageAction) => void;
  onEdit: (message: IMessage) => void;
}

export const MessageItem = ({
  message,
  isCreator,
  restrictions,
  user_id,
  onEdit,
  setSelectedMessage,
}: IProps) => {
  const formattedDate = useMemo(() => formatMessageDate(message.updated_at), [message.updated_at]);
  const [showPanel, setShowPanel] = useState(false);
  const showPanelCallback = useCallback(() => setShowPanel(true), []);
  const hidePanelCallback = useCallback(() => setShowPanel(false), []);
  const showMobilePanelCallback = useCallback(
    () => setSelectedMessage(message, 'mobile-more-menu'),
    [],
  );

  const defaultRestrictions: Required<IConversationRestrictionOptions> = {
    fans_react: false,
    fans_reply: false,
    fans_report: false,
  };

  const managedRestrictions: IConversationRestrictionOptions = useMemo(() => {
    return {
      ...defaultRestrictions,
      ...restrictions,
    };
  }, [restrictions]);

  const info = getBadgeInfo(message?.rich_description);
  const onEditCallback = useCallback(() => onEdit(message), [onEdit, message]);
  const hasAnyRestrictions = Object.values(managedRestrictions).length > 0;

  const isOwnMessage = useMemo(() => message.user_id === user_id, [message.user_id, user_id]);

  return (
    <MessageItemWrapper
      gesture={'hover'}
      onShowPanel={hasAnyRestrictions ? showPanelCallback : undefined}
      onHidePanel={hasAnyRestrictions ? hidePanelCallback : undefined}>
      <div className={twMerge('flex', isOwnMessage ? 'justify-end' : '')}>
        <div className="relative">
          <div className="flex w-full justify-center">
            {info && (
              <div className="border-note mb-8 flex w-[90%] items-center justify-center border-b pb-1 backdrop-blur-lg">
                <Badge
                  type={info?.type}
                  image={info?.image}
                  size="xxs"
                  color={info?.color}
                  className="mr-4 lg:mr-2"
                  showSerialNumber={false}
                />
                <Text type="s" className="text-note">
                  {`${
                    isCreator
                      ? 'Auto message sent with '
                      : 'You received this message after collecting '
                  } ${info?.name}`}
                </Text>
              </div>
            )}
          </div>
          {message.user_id === user_id && (
            <div
              className={twMerge(
                'absolute z-40',
                isOwnMessage ? 'right-[5px] top-[35px]' : 'left-[10px] top-[35px]',
              )}>
              <MessageItemDropdown onEdit={onEditCallback} />
            </div>
          )}
          <div
            className={`relative flex flex-row items-center gap-1 ${
              isOwnMessage ? 'flex-row-reverse' : ''
            }`}>
            <MessageItemWrapper
              gesture={'tap'}
              onShowPanel={managedRestrictions.fans_react ? showMobilePanelCallback : undefined}>
              <div
                className={`flex flex-row gap-1 pb-8 md:pb-0 ${
                  isOwnMessage ? 'flex-row-reverse' : ''
                }`}>
                <Avatar src={message.user_avatar_url} size="s" />
                <div className="flex flex-col">
                  <div
                    className={`flex items-center gap-1 ${isOwnMessage ? 'flex-row-reverse' : ''}`}>
                    <p className="text-sm font-medium">
                      {isOwnMessage ? 'You' : message.user_username}
                    </p>
                    <p className="text-note text-xs font-light" style={{ fontSize: 'x-small' }}>
                      {formattedDate}
                    </p>
                  </div>
                  {info ? (
                    <img
                      src={message?.image_url}
                      alt={message?.name}
                      className={` ${
                        !message?.image_url && 'hidden'
                      } mb-2 h-64 rounded-lg object-cover`}
                    />
                  ) : (
                    <MessageItemFiles files={message.files} />
                  )}
                  <div className="flex flex-col bg-white rounded">
                    {message.description && (
                      <MessageItemDescription message={message} useCase="messaging" />
                    )}
                  </div>
                  {managedRestrictions.fans_react && (
                    <MessageItemReactions
                      message={message}
                      contextUserType={isCreator ? 'creator' : 'fan'}
                    />
                  )}
                </div>
              </div>
            </MessageItemWrapper>
            {showPanel && hasAnyRestrictions && (
              <MessageItemReactionPanel
                message={message}
                contextUserType={isCreator ? 'creator' : 'fan'}
                setSelectedMessage={setSelectedMessage}
                restrictions={managedRestrictions}
              />
            )}
          </div>
        </div>
      </div>
    </MessageItemWrapper>
  );
};

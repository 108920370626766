import { useEffect, useState } from 'react';
import { getCookie } from 'cookies-next';

export interface IAuthOptions {
  accessToken: string;
}

const COOKIES_MAP = {
  accessToken: 'VAULT-accessToken',
};

const cookieService = {
  getAuthCookies() {
    return {
      accessToken: getCookie(COOKIES_MAP.accessToken) || null,
    };
  },
};

interface IAuthSessionState {
  isAuthenticated: boolean;
}
export const useAuth = (): IAuthSessionState => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const { accessToken } = cookieService.getAuthCookies();
    setIsAuthenticated(!!accessToken);
  }, []);

  return {
    isAuthenticated,
  };
};

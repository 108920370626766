import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

interface IDropImage {
  onFileSelected(file: File): void;
  className?: string;
  style?: React.CSSProperties;
  accept?: string;
}

export function DropImage({
  onFileSelected,
  className,
  children,
  style,
  accept,
}: React.PropsWithChildren<IDropImage>) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileSelected(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: accept ? { accept: accept.split(',') } : undefined,
  });
  return (
    <div
      style={style}
      {...getRootProps()}
      className={twMerge(
        'h-[512px] w-[512px] rounded-xl border-4 border-dashed border-gray-400 bg-white',
        className,
      )}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

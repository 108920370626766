'use client';
import {
  Button,
  ContextUserType,
  IMessage,
  IMessagesQueryParams,
  MessageBlocked,
  PostItemReactions,
  ReplyList,
  Separator,
} from '@ui/components';
import { PostItemAuthor } from '../posts/post-item-author';
import { PostItemsMoreOptions } from '../posts/post-item-more-option';
import { MessageItemDescription } from '@ui/components/organisms/message/components/message-list/message-item-description';
import { MessageItemFiles } from '@ui/components/organisms/message/components/message-list/message-item-files';
import { CircleMinus, CirclePlus } from 'lucide-react';
import { twMerge } from 'tailwind-merge';
import { useId, useMemo, useRef, useState } from 'react';
import { useReplyRefContext } from '@ui/contexts';
import { ItemUpdateMessageContentWrapper } from '../common/item-update-message-content-wrapper';
import { useMessageUpdateFormContext } from '@ui/contexts/message-update-form-context';

interface Props {
  comment: IMessage;
  userType: ContextUserType;
  creator_id: string;
  user_id: string;
  enableReply: boolean;
  isPinned?: boolean;
  query: IMessagesQueryParams;
  reportLabel?: string;
  isReply?: boolean;
  main_post_ref: string;
  useSEORoute?: boolean;
}
export const CommentItem = (props: Props) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const id = useId();
  const { setTargetMessage } = useMessageUpdateFormContext();
  const { setTargetRef, setMessage, expandedList, setExpanded } = useReplyRefContext();
  const replyRef = useRef(null);
  const updateRef = useRef(null);

  const hadChildren = useMemo(() => {
    if (!props.comment?.child_messages) return false;
    return props.comment?.child_messages > 0;
  }, [props.comment?.child_messages]);

  const onTriggerReplyForm = () => {
    if (replyRef.current) {
      setMessage({ ...props.comment, queryParams: props.query });
      setTargetRef(replyRef.current);
    }
  };

  const onUpdateTrigger = () => {
    if (updateRef.current) {
      setTargetMessage({
        ...props.comment,
        isPost: false,
        targetRef: updateRef.current,
        type: props.isReply ? 'reply' : 'comment',
        query: props.query,
      });
    }
  };

  return (
    <ItemUpdateMessageContentWrapper ref={updateRef}>
      <div id={id} className="overflow-hidden">
        <div className="flex w-full justify-between">
          <PostItemAuthor
            post={props.comment}
            owner={props.comment.user_id === props.creator_id ? 'creator' : 'fan'}
            creatorId={props.creator_id}
            userType={props.userType}
            IsPinned={props.isPinned}
            useSEORoute={props.useSEORoute}
          />
          <PostItemsMoreOptions
            post={props.comment}
            userType={props.userType}
            userId={props.user_id}
            enableContentFlagging={props.comment.user_id !== props.user_id}
            reportLabel={props.reportLabel}
            onUpdateTrigger={onUpdateTrigger}
            dropdownOpen={dropDownOpen}
            setDropdownOpen={setDropDownOpen}
            isReply={props.isReply}
          />
        </div>
        <div className="flex gap-2">
          <div
            onClick={() => setExpanded(props.comment.id, !expandedList[props.comment.id])}
            className={twMerge(
              'group visible flex w-7 cursor-pointer flex-col items-center justify-between',
              hadChildren ? 'visible' : 'invisible',
            )}>
            <Separator
              orientation="vertical"
              className="group-hover:bg-korDarkGrey flex-auto flex-grow"
            />
            <Button
              onClick={() => setExpanded(props.comment.id, !expandedList[props.comment.id])}
              type="secondary"
              size="smallIcon"
              className="h-fit w-fit min-w-fit rounded-full p-1 lg:h-fit lg:w-fit lg:min-w-fit">
              {!expandedList[props.comment.id] && (
                <CirclePlus className="text-korDarkGrey h-5 w-5" />
              )}
              {expandedList[props.comment.id] && (
                <CircleMinus className="text-korDarkGrey h-5 w-5" />
              )}
            </Button>
            <div className="pb-2"></div>
          </div>
          <div className="w-full space-y-2">
            {props.comment.block && <MessageBlocked userType={props.userType} />}
            {!props.comment.block && (
              <MessageItemDescription
                truncate
                message={props.comment}
                isComment={true}
                useCase="posts"
                className="max-w-fit rounded-none bg-transparent p-0"
              />
            )}
            {!props.comment.block && (
              <MessageItemFiles files={props.comment.files} className="max-w-full" />
            )}
            {!props.comment.block && (
              <PostItemReactions
                query={props.query}
                post={props.comment}
                userType={props.userType}
                hierarchyType="comment"
                onTriggerReplyForm={onTriggerReplyForm}
                enableReply={props.enableReply}
              />
            )}
            <div ref={replyRef}></div>
            {props.enableReply &&
              props.comment.child_thread_id &&
              expandedList[props.comment.id] && <ReplyList {...props} comment={props.comment} />}
          </div>
        </div>
      </div>
    </ItemUpdateMessageContentWrapper>
  );
};

'use client';

import { ReactNode, useState } from 'react';
import { Button, Dialog, DialogContent, DialogHeader, DialogTrigger } from '@ui/components';
import { useAuth } from '@ui/hooks/auth/use-auth';
import { BadgeCheckIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import { createPortal } from 'react-dom';
import korcrewLogoK from '../../../assets/images/korcrewLogoK.png';

interface CreatorInfo {
  communityBadge?: string;
  name?: string;
}

interface WithAuthModalProps {
  children: ReactNode;
  creatorInfo: CreatorInfo | null;
  message?: string;
}

const WithAuthModal = ({ children, creatorInfo, message }: WithAuthModalProps) => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();

  // Handle the badge claim action
  const handleBadgeClaim = () => {
    if (!isAuthenticated && creatorInfo?.communityBadge && creatorInfo?.name) {
      setIsModalOpen(false);
      router.push(`/badge/?creator=${creatorInfo.name}&badgeId=${creatorInfo.communityBadge}`);
    }
  };

  const handleInteraction = (e: React.MouseEvent | React.TouchEvent | React.KeyboardEvent) => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  };

  // Create a modal container for portal if it doesn't exist
  if (typeof window !== 'undefined' && !document.getElementById('modal-root')) {
    const modalRoot = document.createElement('div');
    modalRoot.id = 'modal-root';
    // Apply CSS to position it at the top of the stacking context
    modalRoot.style.position = 'fixed';
    modalRoot.style.top = '0';
    modalRoot.style.left = '0';
    modalRoot.style.width = '100%';
    modalRoot.style.height = '100%';
    modalRoot.style.zIndex = '100'; // Very high z-index to ensure modal is above everything
    document.body.appendChild(modalRoot);
  }

  return (
    <>
      {/* The children content that triggers the modal */}
      <div
        className="w-full h-full relative"
        style={{
          zIndex: !isAuthenticated && isModalOpen ? 999 : 'auto', // High z-index when not authenticated
        }}>
        {/* Overlay to catch all interactions when not authenticated */}
        {!isAuthenticated && (
          <div
            className="absolute inset-0 w-full h-full"
            style={{
              cursor: 'pointer',
              backgroundColor: 'transparent',
              zIndex: 999,
            }}
            onClick={handleInteraction}
            onTouchStart={handleInteraction}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleInteraction(e);
              }
            }}
            role="button"
            tabIndex={0}
            aria-label="Login required"
          />
        )}

        {/* The actual wrapped content */}
        <div
          className="w-full h-full"
          style={{
            pointerEvents: isAuthenticated ? 'auto' : 'none', // Prevent direct interaction when not authenticated
          }}>
          {children}
        </div>
      </div>

      {/* Use portal for the modal to ensure it's at the top of the DOM */}
      {typeof window !== 'undefined' &&
        createPortal(
          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogContent className="bg-white p-0 rounded-none overflow-hidden shadow-xl sm:max-w-3xl border-0 flex flex-row">
              {/* Left side - Gradient background with logo */}
              <div className="hidden md:flex w-1/2 bg-gradient-to-br from-orange-400 to-orange-600 items-center justify-center p-8">
                <div className="flex items-center justify-center w-32 h-32 bg-white/10 rounded-full backdrop-blur-sm">
                  <img src={korcrewLogoK.src} alt="KOR Logo" width={80} height={80} />
                </div>
              </div>

              {/* Right side - Content */}
              <div className="w-full md:w-1/2 p-8">
                <DialogHeader className="mb-6 flex flex-col items-center">
                  <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center mb-4 shadow-inner">
                    <BadgeCheckIcon className="w-8 h-8 text-primary" />
                  </div>
                  <h2 className="text-2xl font-semibold text-gray-900">Claim a Badge</h2>
                </DialogHeader>

                <div className="text-center mb-8">
                  <p className="text-gray-600">
                    {message
                      ? message
                      : 'You need to be a member of this KOR space to use this feature. Claim this badge to become a member.'}
                  </p>
                </div>

                <div className="flex flex-col gap-4">
                  <Button
                    type="outline"
                    className="w-full py-3 px-4 rounded-lg transition-all duration-200 hover:shadow-md"
                    onClick={handleBadgeClaim}
                    disabled={!creatorInfo?.communityBadge || !creatorInfo?.name}>
                    Claim Badge
                  </Button>
                  <Button
                    type="primary"
                    className="w-full py-3 px-4 rounded-lg transition-all duration-200 hover:shadow-md"
                    onClick={() => {
                      setIsModalOpen(false);
                      router.push('/auth/');
                    }}>
                    Login
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>,
          document.getElementById('modal-root') as HTMLElement,
        )}
    </>
  );
};

export default WithAuthModal;

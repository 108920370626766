'use client';
import {
  CommentItem,
  ContextUserType,
  IMessage,
  ReplyFormPortal,
  useMessageContext,
} from '@ui/components/organisms';
import { CommentOrReplyForm } from './comment-or-reply-form';
import { useEffect, useId } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSkeleton } from '@ui/components';
import { CommentItemSkeleton } from './comment-item-skeleton';
import { ContentType } from '@ui/constants/context';

interface Props {
  userType: ContextUserType;
  post: IMessage;
  creator_id: string;
  user_id: string;
  main_post_ref: string;
  enableReply: boolean;
  contentType: ContentType;
  useSEORoute?: boolean;
}
export const CommentList = (props: Props) => {
  const messageState = useMessageContext(props.userType);
  const id = useId();

  useEffect(() => {
    messageState.onSetThread(props.post.child_thread_id);
    messageState.onSetCreator(props.creator_id);
    messageState.onSetConversation(props.post.conversation_id);
    messageState.onSetFan(props.user_id);
  }, [props.post.child_thread_id, props.creator_id, props.post.conversation_id, props.user_id]);

  if (messageState.isLoading && messageState.messages.length === 0) {
    return (
      <div className="flex flex-col gap-y-6 pt-4">
        <LoadingSkeleton className=" m-w-[70vw] h-7 w-full rounded-sm" />
        <CommentItemSkeleton />
        <CommentItemSkeleton />
      </div>
    );
  }

  return (
    <>
      <ReplyFormPortal {...props} queryParams={messageState.query} />
      <div className="space-y-4 mb-4">
        <CommentOrReplyForm
          creator_id={props.post.creator_id}
          thread_id={props.post.child_thread_id}
          user_id={messageState.user_id}
          contentType={props.contentType === 'comment' ? 'reply' : 'comment'}
          userType={props.userType}
          activateToolbarOnFocus
          createThreadIfNotExist
          message_id={props.post.id}
          conversation_id={props.post.conversation_id}
          queryParam={messageState.query}
          main_post_ref={props.main_post_ref}
        />

        {messageState.pinned_messages?.length > 0 && (
          <div className="mb-4 space-y-2 flex items-start flex-col w-full border-b border-b-orange-100 relative pb-5">
            {messageState.pinned_messages.map((comment) => (
              <div key={comment.id} className="relative w-full">
                <CommentItem
                  key={comment.id}
                  comment={comment}
                  userType={props.userType}
                  user_id={messageState.user_id}
                  creator_id={comment.creator_id || props.creator_id}
                  enableReply={props.enableReply}
                  reportLabel="Report comment"
                  isReply={false}
                  query={messageState.query}
                  main_post_ref={props.main_post_ref}
                  isPinned
                />
              </div>
            ))}
          </div>
        )}

        <div className="pb-10">
          <InfiniteScroll
            className="scrollbar-none space-y-2"
            dataLength={messageState.messages.length}
            next={messageState.onNextPage}
            hasMore={messageState.hasMorePages}
            scrollableTarget={id}
            loader={
              <div className="py-4">
                <CommentItemSkeleton />
              </div>
            }>
            {messageState.messages.map((comment) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                userType={props.userType}
                user_id={messageState.user_id}
                creator_id={comment.creator_id || props.creator_id}
                enableReply={props.enableReply}
                reportLabel="Report comment"
                isReply={false}
                query={messageState.query}
                main_post_ref={props.main_post_ref}
                useSEORoute={props.useSEORoute}
              />
            ))}
            {messageState.isLoading && (
              <div className="flex flex-col gap-y-6 pt-4">
                <LoadingSkeleton className=" m-w-[70vw] h-7 w-full rounded-sm" />
                <CommentItemSkeleton />
                <CommentItemSkeleton />
              </div>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

import {
  IMessage,
  TooltipProvider,
  useMessageContext,
  TooltipTrigger,
  TooltipContent,
  Button,
  ContextUserType,
  TMessageAction,
  COMMON_REACTIONS,
  IConversationRestrictionOptions,
} from '@ui/components';
import { HoverCard, HoverCardContent, HoverCardTrigger, Tooltip } from '@ui/components/ui';
import { Smile, EllipsisVertical, Plus, Flag } from 'lucide-react';
import { useState } from 'react';
import { useClickOutside } from '@ui/hooks';

interface IProps {
  message: IMessage;
  contextUserType: ContextUserType;
  restrictions: IConversationRestrictionOptions;
  setSelectedMessage: (message: IMessage, action: TMessageAction) => void;
}

export const MessageItemReactionPanel = ({
  message,
  contextUserType,
  restrictions,
  setSelectedMessage,
}: IProps) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openMoreMenu, setOpenMoreMenu] = useState(false);
  const messageState = useMessageContext(contextUserType);
  const emojiHoverCardRef = useClickOutside(() => setOpenEmoji(false));
  const moreMenuRef = useClickOutside(() => setOpenMoreMenu(false));
  const onReactToMessage = (emoji: string) =>
    messageState.onReactToMessage(message.id, message.slug, 'reaction', emoji, messageState.query);

  return (
    <div className="flex flex-row">
      {restrictions.fans_report && (
        <PanelTooltip text="More">
          <HoverCard openDelay={0} closeDelay={0} open={openMoreMenu}>
            <HoverCardTrigger>
              <Button
                type="icon-primary"
                className="hover:bg-inputActive/50 h-6 w-6 rounded-sm border-none bg-transparent"
                onClick={() => setOpenMoreMenu(true)}>
                <EllipsisVertical className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>

            <HoverCardContent
              ref={moreMenuRef}
              hideWhenDetached
              className="w-36 rounded border-none p-1 shadow-none">
              <div className="flex flex-col gap-1">
                <Button
                  type="icon-primary"
                  className="hover:bg-inputActive/50 flex h-6 w-6 min-w-full flex-row justify-between rounded-sm border-none bg-transparent p-3 text-red-800"
                  onClick={() => setSelectedMessage(message, 'report')}>
                  <span className="text-sm">Report</span> <Flag className="h-4 w-4 text-red-800" />
                </Button>
              </div>
            </HoverCardContent>
          </HoverCard>
        </PanelTooltip>
      )}

      {restrictions.fans_react && (
        <PanelTooltip text="React">
          <HoverCard openDelay={0} closeDelay={0} open={openEmoji}>
            <HoverCardTrigger>
              <Button
                type="icon-primary"
                className="hover:bg-inputActive/50 h-6 w-6 rounded-sm border-none bg-transparent"
                onClick={() => setOpenEmoji(true)}>
                <Smile className="h-4 w-4" />
              </Button>
            </HoverCardTrigger>

            <HoverCardContent
              ref={emojiHoverCardRef}
              hideWhenDetached
              className="w-fit rounded-full">
              <div className="flex flex-row gap-1">
                {COMMON_REACTIONS.map((emoji) => (
                  <Button
                    key={`emoji-${emoji}`}
                    type="icon-primary"
                    className="hover:bg-inputActive/50 h-6 w-6 rounded-sm border-none bg-transparent"
                    onClick={() => onReactToMessage(emoji)}>
                    {emoji}
                  </Button>
                ))}
                <Button
                  type="icon-primary"
                  className="hover:bg-inputActive/50 h-6 w-6 rounded-sm border-none bg-transparent"
                  onClick={() => setSelectedMessage(message, 'react')}>
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </HoverCardContent>
          </HoverCard>
        </PanelTooltip>
      )}
    </div>
  );
};

interface TooltipProps {
  children: React.ReactNode;
  text: string;
}
const PanelTooltip = ({ children, text }: TooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <span>{children}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

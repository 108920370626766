import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
const interactionAtom = atom({
  isIntercepted: false,
});
export const useInteractionGuard = () => {
  const [state, setState] = useAtom(interactionAtom);
  const interceptInteraction = useCallback((fn, message) => {
    return (...args) => {
      var _a, _b;
      // Handle event if first argument is an event
      if (
        ((_a = args[0]) === null || _a === void 0 ? void 0 : _a.preventDefault) &&
        ((_b = args[0]) === null || _b === void 0 ? void 0 : _b.stopPropagation)
      ) {
        args[0].preventDefault();
        args[0].stopPropagation();
      }
      setState({
        isIntercepted: true,
        message,
        pendingFn: { fn, args },
      });
    };
  }, []);
  return {
    isIntercepted: state.isIntercepted,
    message: state.message,
    pendingFn: state.pendingFn,
    interceptInteraction,
    clearInterception: () =>
      setState({ isIntercepted: false, message: undefined, pendingFn: undefined }),
  };
};
